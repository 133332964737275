import React from 'react'
import Subjects from "../information/Subjects";
import NewsForm from "./newsform";
import ReactDOM from 'react-dom';
import Pagination from "../../elements/Pagination";

class NewsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            countItems:0,
            allNewsCount:0

        };

    }

    componentDidMount() {
        let url = global.apiLink + 'news/list?limit=10';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url).then(res => res.json())
            .then((result) => {
                if (result.payload) {
                    this.setState({
                        list: result.payload.news,
                        countItems: result.payload.length,
                        allNewsCount:result.payload.count_news
                    });
                    if(result.payload.count_news >0 ){
                        let count_news = parseInt(result.payload.count_news);
                        ReactDOM.render(<Pagination totalRecords={count_news} pageLimit={10} onPageChanged={this.onPageChanged}/>,document.getElementById('paginate'))
                    }
                }
            })
            .catch((error)=>{
                console.log('some error');
                console.log(error);
            });
    }

    getChildButton = (parentId, classname_str = "", tagName = "i") => {
        return (
            <>
                {React.createElement(tagName, {
                    className: classname_str + 'fas fa-plus-circle ml-2 resource_add_child',
                    dataPid: parentId,
                    title: 'Добавить регион',
                    onClick: () => this.addNews()
                },)}
            </>
        );
    }
    onPageChanged = paginationData =>{
        let currentPage = paginationData.currentPage;
        let url = global.apiLink +'news/list?limit=10&page='+currentPage;
        fetch(url).then(res=>res.json())
            .then((result)=>{
                const  news = result.payload.news;
                console.log(news);
                this.setState({list:news});
            });
    }
    addNews() {
        ReactDOM.unmountComponentAtNode(document.getElementById('form-news'));
        window.location = "/news/0";
        ReactDOM.render(<NewsForm id="0"/>, document.getElementById('form-news'));
    }

    render() {
        const {countItems, list} = this.state;
        //if(countItems){
        const treeElements = list.map((element,i) =>
            <li key={element.id}>
                <i className="far fa-folder isfolder-btn mr-2" aria-hidden="true"/>
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <a className="resource_title" data-toggle="tooltip" data-placement="top"
                   title={element.title} href={'/news/'+ element.id}>
                    <span>{element.title}</span>
                </a>
                <i className="fas fa-minus-circle ml-2 resource_remove" title="Удалить"
                   aria-hidden="true" onClick={()=>this.delete(element.id,i)}/>

            </li>
        );
        //  }
        let pagination = null;


        return (
            <div className="col-md-4">
                <div className="row">
                    <ul className="mb-1 pb-0">
                        <li key="0">
                            {React.createElement('i', {className: 'fas fa-globe'},)}

                            <span className="resource_title " data-toggle="tooltip" data-placement="top"
                                  title="Рут"></span> <a className="resource_title" data-toggle="tooltip"
                                                         data-placement="top" title={"Документы"}>Новости</a>
                            {this.getChildButton(0)}
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3">
                        <ul>
                            {treeElements}
                        </ul>
                        <div id="paginate">
                        </div>


                    </div>
                </div>

            </div>

        );
    }
}

export default NewsList;