import React from 'react';
import ReactDOM from 'react-dom';
import Toppanel from '../top_panel';

import "../../../global.js";

export class SetConsulter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roomList:[],
            userList:[],
            roomVisitorsList:[],
            room_id: null,
        }
    }

    ListRoomsHandler() {
        let url = global.apiLink + 'api/chat/chat/list-room';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                if(response.status === 200){
                    this.setState({
                        roomList: response.payload.array
                    });
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');

                }
            });
    }


    GetRoomVisitorsHandler(room_id) {
        let url = global.apiLink + 'api/chat/chat/list-room-visitors?room_id='+room_id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                console.log(response);
                if(response.status === 200){
                    this.setState({
                        roomVisitorsList: response.payload.array
                    });
                    console.log(response.payload.array);
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');
                }
            });
    }

    TaskSelectHandler(room_id) {
                    this.setState({
                        room_id: room_id
                    });
        this.GetRoomVisitorsHandler(room_id);
        let url = global.apiLink + 'api/user/list';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                console.log(response);
                if(response.status === 200){
                    this.setState({
                        userList: response.payload.userlist
                    });
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');
                }
            });
    }
    
    SetConsulterToRoomHandler(user_id) {
        let url = global.apiLink + 'api/chat/chat/enter-room?room_id='+this.state.room_id+'&user_id='+user_id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                console.log(response);
                if(response.status === 200){
                    alert("Консультант назначен");
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');
                }
            });
    }
    
    componentDidMount() {
        this.ListRoomsHandler();
    }

    render(){
        const {roomList, userList, roomVisitorsList} = this.state;
        //console.log(roomList);
        console.log(roomVisitorsList);
        return (
            <>
                <Toppanel title="Управление обращениями" />
                <div className="row">
                    <div id="roomList-work-area" className="col-md-6">
                        {roomList && roomList.map(element=> <>
                            <div>
                                <div className="card">
                                    <div className="card-body" onClick={ () => this.TaskSelectHandler(element.id)}>
                                        {element.title}
                                    </div>
                                </div>
                            </div>
                        </>)}
                    </div>
                    <div id="userList-work-area" className="col-md-6">
                        {userList && userList.map(element => {
                            let classNam = "";

                            //" text-white bg-primary";
                            return (<>
                                <div>
                                    <div className={"card" + classNam}>
                                        <div className="card-body" onClick={ () => this.SetConsulterToRoomHandler(element.id)}>
                                            {element.name}
                                        </div>
                                    </div>
                                </div>
                            </>);
                        
                        })}
                    </div>
                </div>
            </>
        );
    }

}

export default SetConsulter;