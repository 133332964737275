import React from 'react';

class Dashboard extends React.Component {

    render(){
        
        return (
            <div id="header" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h1>Панель управления</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Dashboard;