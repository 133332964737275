import React from 'react';
import Toppanel from "../../top_panel";
import TypeSubjectList from "./typesubjectlist";
import TypeSubjectForm from "./typesubjectform";
import ReactDOM from "react-dom";

class TypeSubject extends React.Component {
    constructor(props) {
        super(props);
        let idSubject = 0
        if (props.match.params.id) {
            idSubject= props.match.params.id;
        }
        this.state = {
            idSubject:idSubject
        };
        console.log(this.state);
    }

    componentDidMount() {
        let {idSubject} = this.state;
        if(idSubject){
            ReactDOM.render(<TypeSubjectForm id={idSubject}/>,document.getElementById('subject-work-area'));
        }
    }

    render() {
        return (
            <div>
                <Toppanel title="Управление типами справочной информации"/>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <TypeSubjectList/>
                            <div id="subject-work-area" className="col-md-8 float-right">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default TypeSubject;