import React from 'react';
import ReactDOM from 'react-dom';
import Toppanel from '../top_panel';
import docsstyle from './DocsForm.module.css';

class DocsResourceviewerForm extends React.Component {

    constructor(props) {
        super(props);
        let parent_id = this.props.parent_id ? this.props.parent_id : 0;
        if (this.props.updateTree) {
            this.updateTree = this.props.updateTree;
        }
        this.state = {
            id: this.props.id,
            name: '',
            description: '',
            parents: [],
            active: 0,
            parent_id: parent_id,
            published: 0,
            file: null,
            filepdf: null,
            filedocx: null,
            variables: []
        };
    }

    componentDidMount() {
        let url_sections = global.apiLink + "/doc/document/list?only_parents=Y";
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url_sections,{
            headers:headers
        })
            .then(res => res.json())
            .then(
                (result) => {

                    let parents = Object.values(result.payload);

                    parents.unshift({id: 0, name: "Не выбранно"})
                    this.setState({parents: parents})
                }
            )

        let url_document = global.apiLink + "/doc/document/get?id=" + this.props.id;
        fetch(url_document,{
            headers:headers
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.payload) {
                        this.setState({
                            name: result.payload.name,
                            description: result.payload.description,
                            published: result.payload.published,
                            parent_id: result.payload.parent_id,
                            variables: result.payload.variables,
                            active: result.payload.active,
                            file: result.payload.files,
                            filepdf: result.payload.filepdfs,
                            filedocx: result.payload.filedocxs
                        });
                    }
                }
            )

    }

    saveDoc(e) {
        e.preventDefault();

        const tokenAPI = localStorage.getItem("tokenAPI");
        let formData = new FormData(e.target);
        formData.append('token', tokenAPI);
        let url = global.apiLink + "/doc/document/edit";
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url, {
            method: 'POST',
            headers:headers,
            body: formData
        }).then((result) => {
            // if(this.props.updateTree){
            this.props.updateTree(formData.get('parent_id'));
            //  }

        });

        return false;
    }

    deleteFile = async (idDoc, typeFile = "templateDocx") => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        
        let url = global.apiLink + "/doc/document/delete-file?id-doc=" + idDoc;
        if(typeFile == "pdf"){
            url = global.apiLink + "/doc/document/delete-file?id-pdf=" + idDoc;
        }else if(typeFile == "docx"){
            url = global.apiLink + "/doc/document/delete-file?id-docx=" + idDoc;
        }

        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        const api_url = await fetch(url,{
            headers:headers
        });
        const json = await api_url.json();
        if (json.status !== 200) {
            alert('something went wrong =(');
            return;
        }
        if(typeFile == "pdf"){
            this.setState({filepdf: null});
        }else if(typeFile == "docx"){
            this.setState({filedocx: null});
        }else if(typeFile == "templateDocx"){
            this.setState({file: null});
        }

        return true;
    }

    addVariable() {
        let {variables} = this.state;
        variables.push({id: '', name: '', title: ''});
        this.setState({variables: variables});
    }

    bindChangeName(i, event) {
        let {variables} = this.state;
        variables[i].name_variable = event.target.value;
        this.setState({variables: variables});
    }

    bindChangeTitle(i, event) {
        let {variables} = this.state;
        variables[i].title = event.target.value;
        this.setState({variables: variables});
    }

    downloadFile = async (e) => {
        e.preventDefault();
        let url = global.apiLink + '/doc/document/generate';
        let formData = new FormData(e.target);
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let result = await fetch(url,{
            method:'POST',
            body:formData,
            headers:headers
        });
        let blob = await result.blob();
        console.log(blob);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download=this.state.file.name;
        link.click();
    }

    render() {
        let parents = this.state.parents;

        const options = parents.map((doc) =>
            <option key={doc.id} value={doc.id}>{doc.name}</option>
        )
        console.log(this.state.file);
        return (

            <div>
                <form id="form-resource-create" onSubmit={(e) => this.saveDoc(e)}>
                    <input type="hidden" id="id-form-edit" name="id" value={this.state.id}/>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm"
                                    onClick={this.resourceSave}>Сохранить
                            </button>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Имя</label>
                                <input type="text" className="form-control" name="name" id="form_resource_title"
                                       value={this.state.name} onChange={e => this.setState({name: e.target.value})}
                                       placeholder="Имя документа"/>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Родительский элемент</label>
                                <select className="custom-select"
                                        onChange={e => this.setState({parent_id: e.target.value})} name="parent_id"
                                        value={this.state.parent_id}>{options}</select>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="file">Файл с переменными</label>
                                        <br/>
                                        {!this.state.file
                                            ? <input type="file" className="form-control" name="file"/>
                                            : <div id="file-holder">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    className="bi bi-file-earmark-break-fill file-ico"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M0 10.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zM2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V9H2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1z"/>
                                                    <path d="M2 13a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1H2v1z"/>
                                                </svg>
                                                <p>{this.state.file.name}</p>
                                                <i className="fas fa-minus-circle ml-2 resource_remove"
                                                onClick={() => this.deleteFile(this.state.id)}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="filepdf">Файл pdf</label>
                                        <br/>
                                        {!this.state.filepdf
                                            ? <input type="file" className="form-control" name="filepdf"/>
                                            : <div id="file-holder">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    className="bi bi-file-earmark-break-fill file-ico"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M0 10.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zM2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V9H2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1z"/>
                                                    <path d="M2 13a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1H2v1z"/>
                                                </svg>
                                                <p>{this.state.filepdf.name}</p>
                                                <i className="fas fa-minus-circle ml-2 resource_remove"
                                                onClick={() => this.deleteFile(this.state.id, "pdf")}/>
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="filedocx">Файл docx</label>
                                        <br/>
                                        {!this.state.filedocx
                                            ? <input type="file" className="form-control" name="filedocx"/>
                                            : <div id="file-holder">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                    className="bi bi-file-earmark-break-fill file-ico"
                                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M0 10.5a.5.5 0 0 1 .5-.5h15a.5.5 0 0 1 0 1H.5a.5.5 0 0 1-.5-.5zM2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V9H2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1z"/>
                                                    <path d="M2 13a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-1H2v1z"/>
                                                </svg>
                                                <p>{this.state.filedocx.name}</p>
                                                <i className="fas fa-minus-circle ml-2 resource_remove"
                                                onClick={() => this.deleteFile(this.state.id, "docx")}/>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="formGroupExampleInput">Переменные для шаблона</label>

                                        {this.state.variables.map((v, index) =>
                                            <fieldset className={docsstyle.fieldset}>
                                                <label>Переменная</label>
                                                <input type="hidden" name={"variables[" + index + "][id]"} value={v.id}/>

                                                <input type="text" name={"variables[" + index + "][name]"}
                                                    value={v.name_variable}
                                                    onChange={this.bindChangeName.bind(this, index)}
                                                    className="form-control"/>
                                                <label>Тайтл</label>
                                                <input type="text" name={"variables[" + index + "][title]"} value={v.title}
                                                    onChange={this.bindChangeTitle.bind(this, index)}
                                                    className="form-control"/>
                                            </fieldset>
                                        )}

                                    </div>
                                    <div className={"form-group " + docsstyle.add_var_holder}>
                                        <button type="button" onClick={() => this.addVariable()} className="btn btn-info ">
                                            добавить переменную
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="form-group">
                                <label htmlFor="description">Описание</label>
                                <textarea className="form-control" name="description" id="form_resource_alias"
                                          onChange={e => this.setState({description: e.target.value})}
                                          value={this.state.description}/>

                            </div>
                        </div>


                    </div>
                </form>

                    {(this.state.variables.length > 0 && this.state.file)&&
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={(e) => this.downloadFile(e)} >
                                    <input type="hidden" name="file-id" value={this.state.file.id}/>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="form-group ">
                                                {this.state.variables.map((v, index) =>
                                                    <fieldset className={docsstyle.fieldset}>
                                                        <input type="hidden" name={"varval["+index+"][name]"} value={v.name_variable}/>
                                                        <input type="text" name={"varval[" + index + "][title]"} value={v.title}
                                                            className="form-control"/>
                                                        <input type="text" name={"varval[" + index + "][value]"}
                                                            className="form-control"/>
                                                    </fieldset>
                                                )}

                                            </div>
                                            <div className={"form-group " + docsstyle.add_var_holder}>
                                                <button type="submit"  className="btn btn-info ">
                                                    Скачать заполненный шаблон
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>  
                    }
            </div>


        );

    }
}

export default DocsResourceviewerForm;