import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import Signin from "./components/signin";
import HeaderT from "./components/header";
import Rightsidebar from "./components/rightsidebar/rightsidebar";
import BodyT from "./components/body";
import Clock from "./components/clock";
import { BrowserRouter } from 'react-router-dom';



export class TreeCodex extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
          refreshtime: 1,
          data: {},
          displayChild: [],
		  AllLoaded: []
      };
    }

    resourceGet = async (resourceid) => {
        const tokenAPI = localStorage.getItem("tokenAPI");
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        const api_url = await 
        fetch(global.apiLink+`api/codex/codex/get?token=${tokenAPI}&id=${resourceid}`,{
            headers:headers
        });
        const json = await api_url.json();
        if(json.status !== 200){
            alert("Не верные данные авторизации");
            localStorage.removeItem('tokenAPI');
            return false;
        }
        var resourceData = await json.payload;
        if(resourceData){
          return resourceData;  
        }
        return false;
    }


    getThisDocId = () => {
      let pathname = window.location.pathname;
      const alias = decodeURIComponent(pathname).split('/')[2];
      const locParam = alias.split('_');
      const resourceId = locParam[1];
      //console.log(resourceId);
      return resourceId;
    }

    decodeHTML = (html) => {
      var txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    createMarkup = (s) => {
      return {__html: s};
    }
  
    resourceAddChild = async (parent_id) => {
      document.getElementById('resource-create').style.display = "block";
      document.getElementById('form_resource_parentid').value = parent_id;
      document.getElementById('parentIdShow').innerHTML = parent_id;
      
      document.getElementById('form-resource-create').removeAttribute("data-resourceid");
      document.getElementById('form_resource_title').value = "";
      document.getElementById('form_resource_alias').value = "";
      //document.getElementById('form_resource_description').value = "";
      document.getElementById('form_tags').value = "";
      document.getElementById('form_resource_published').value = 1;
      document.getElementById('form_resource_id_template').value = "";
      document.getElementById('form_resource_content').innerHTML = "";
      try {
        window.tinymce.get('form_resource_content').setContent("");
      } catch (err) {
          console.log(err);
      }
      document.getElementById('parentEditBtn').style.display = "none";
      
      window.refreshTinyMCEInit();
      if(parent_id != 0){
        document.getElementById('parentEditBtn').style.display = "block";
        var parentInfo = await this.resourceGet(parent_id);
        document.getElementById('parentEditBtn').innerHTML = parentInfo.title;
      }
      document.getElementById('form_resource_sort').value = "";
      document.getElementById('codex-tvlist-container').innerHTML = "";
      document.getElementById('codex-addchild-container').style.display = "none";
      document.getElementById('form_resource_title').focus();
  }

  resourceEdit = async (resourceid) => {
    let swlwctwdMenuElement = document.querySelectorAll("#codex-tree-container li");
    for(let i=0;i<swlwctwdMenuElement.length;i++){
      swlwctwdMenuElement[i].removeAttribute("style");
    }
    swlwctwdMenuElement = document.querySelector("li.resourceid"+resourceid+"");
    if(swlwctwdMenuElement !== null)swlwctwdMenuElement.style.fontWeight = 600;
    document.getElementById("codex-tvlist-container").innerHTML = "";
      document.getElementById('resource-create').style.display = "block";
      setTimeout(()=>{window.refreshTinyMCEInit();}, 100);
      var resourceData = await this.resourceGet(resourceid);
      if(resourceData){
          let parentInfo = await this.resourceGet(resourceData.parent_id);
          document.getElementById("parentIdShow").innerHTML = resourceData.parent_id;
          document.getElementById("parentEditBtn").innerHTML = parentInfo.title;
          document.getElementById('form-resource-create').setAttribute("data-resourceid", resourceid);
          if(resourceData.title !== undefined){
              document.getElementById('form_resource_title').setAttribute("data-value", resourceData.title);
              document.getElementById('form_resource_title').value = resourceData.title;
          }
          if(resourceData.alias !== undefined){
              document.getElementById('form_resource_alias').setAttribute("data-value", resourceData.alias);
              document.getElementById('form_resource_alias').value = resourceData.alias;
          }
          /*if(resourceData.description !== undefined){
              document.getElementById('form_resource_description').setAttribute("data-value", resourceData.description);
              document.getElementById('form_resource_description').value = resourceData.description;
          }/**/
          if(resourceData.tags !== undefined){
              document.getElementById('form_tags').setAttribute("data-value", resourceData.tags);
              document.getElementById('form_tags').value = resourceData.tags;
          }
          if(resourceData.parent_id !== undefined)document.getElementById('form_resource_parentid').value = resourceData.parent_id;
          if(resourceData.published !== undefined){
            document.getElementById('form_resource_published').value = parseInt(resourceData.active);
          }
          if(resourceData.type_node !== undefined){
            document.getElementById('form_resource_id_template').value = resourceData.type_node;
          }
          if(resourceData.sort !== undefined)document.getElementById('form_resource_sort').value = resourceData.sort;
          if(resourceData.content !== undefined){
              document.getElementById('form_resource_content').innerHTML = resourceData.content;
              try {
                window.tinymce.get('form_resource_content').setContent(resourceData.content);
              } catch (err) {
                  console.log(err);
              }
              window.refreshTinyMCEInit();
              console.log(resourceData.content);
          }
          let getArticleItems = await this.getArticleItems(resourceid);
          var ArrayArticleItems = getArticleItems.payload.list.nodes;
          
          var ftable = document.createElement("table");
          ftable.classList.add("table");
          ftable.classList.add("mt-2");
          ftable.classList.add("table-striped");
          ftable.classList.add("table-bordered");
          ftable.innerHTML = "<tbody></tbody>";

          document.getElementById("codex-tvlist-container").append(ftable);
          ArrayArticleItems.map(el =>{
            this.FormationArticleItems(el);
          });
          this.formationTablePanel(ArrayArticleItems);
          
          document.getElementById('codex-addchild-container').style.display = "block";
          ReactDOM.render(
            <>{this.getChildButton( resourceid, "btn btn-info ", "div" )}</>,
            document.getElementById("codex-addchild-container")
          );
      }   
  }

  getArticleItems = async (docid = 0) => {
		const tokenAPI = localStorage.getItem("tokenAPI");
		let linkGet = global.apiLink + `api/codex/codex/list?token=${tokenAPI}&recursive=1&pid=`+docid+`&lvl=1`;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
		const api_url = await fetch(linkGet,{
		    headers:headers
        });
		const json = await api_url.json();
		
		if(json.status !== 200){
			alert("Не верные данные авторизации getCodexTree("+docid+")");
			localStorage.removeItem('tokenAPI');
			return false;
    }
    return json;
  } 

  isNode =  ( o ) => {
    return (
      typeof Node === "object" ? o instanceof Node : 
      o && typeof o === "object" && typeof o.nodeType === "number" && typeof o.nodeName==="string"
    );
  }
    
  formationTablePanel = (ArrayArticleItems) =>{
    let mtp = document.getElementsByClassName("mig_table_panel");
    for(var el in mtp) {
      let targetContainer = document.getElementsByClassName("mig_table_panel")[el];
        //let MigCount = document.getElementsByClassName("mig_table_panel")[el].getAttribute("data-migcount");
        if(ArrayArticleItems[el] !== undefined)if(ArrayArticleItems[el].element !== undefined){
          let ElId = ArrayArticleItems[el].element.id;
          if(this.isNode(targetContainer)) ReactDOM.render(
              <div>
                  <button onClick={ () => this.resourceEdit(ElId) } className='btn btn-primary btn-sm mr-2' name='migel-edit' data-n=''><i className='far fa-edit'></i></button>
                  <button className='btn btn-danger btn-sm' name='migel-remove' data-n=''><i className='far fa-trash-alt'></i></button>
              </div>
              , targetContainer
          );
        }
    };
}
  FormationArticleItems = (el) => {
    console.log(el.element);
    let counterItem = document.getElementById("codex-tvlist-container").querySelectorAll(".ArticleItem").length + 1;
    let mig_tv_items = document.getElementById("codex-tvlist-container").querySelector("tbody");
    let outputmigel = "<tr class='ArticleItem'>";
    outputmigel += "<td width='20'>"+counterItem+"</td>";
    let TRContent = el.element.content;
    if(TRContent == "")TRContent = el.element.title;
    outputmigel += "<td>"+TRContent+"</td>";
    outputmigel += "<td width='100' data-migcount='"+el.element.id+"' class='mig_table_panel'></td>";
    outputmigel += "</tr>";
    mig_tv_items.innerHTML = mig_tv_items.innerHTML + outputmigel;
  }

  getCodexTree = async (docid = 0) => {

		const tokenAPI = localStorage.getItem("tokenAPI");
		let linkGet = global.apiLink + `api/codex/codex/list?token=${tokenAPI}&recursive=1&pid=`+docid+`&lvl=1`;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
		const api_url = await fetch(linkGet,{
		    headers:headers
        });
		const json = await api_url.json();
		
		if(json.status !== 200){
			alert("Не верные данные авторизации getCodexTree("+docid+")");
			localStorage.removeItem('tokenAPI');
			return false;
		}
		
		this.state.data = await json.payload.list;
		this.state.AllLoaded[docid] = await json.payload.list;
		//this.setState("AllLoaded", thisDocs);

		var targetClassName = 'codex-tree-container';
		if(docid !== 0){
			targetClassName = 'submenu_Id' + docid;
		}
		//console.log(linkGet);
		//console.log(targetClassName);
		//console.log(this.state.AllLoaded);
		
		const result = await this.state.data.nodes.map(el => {
			let linkHref = el.element.alias + "_" + el.element.id;
			let isActive = "";
			if(this.state.displayChild.indexOf(el.element.id) !== -1){isActive = "active";}
			return (
				<li 
					key={el.element.id}
					className={isActive + "resourceid"+el.element.id}
					data-docid={el.element.id}
				>
					{(el.element.type_node != "article" && this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
					{(this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
					{/*el.element.type_node == "codex" && React.createElement('i',{className: 'fs-fa-empty mr-2'},)/**/}
					{el.element.type_node == "article" && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
					{el.element.type_node != "article" && React.createElement('i',{className: 'fas fa-book mr-2'},)}
					<a className="resource_title" onClick={() => this.resourceEdit(el.element.id)} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
          {React.createElement('i',{className: 'fas fa-plus-circle ml-2 resource_add_child', dataPid: el.element.id, title: 'Добавить дочерний ресурс', onClick: () => this.resourceAddChild(el.element.id)},)}
          <div id={"submenu_Id"+el.element.id}></div>
					
				</li>
			)
		});
		
		ReactDOM.render(
			<ul>
			{result}
			</ul>,
			document.getElementById(targetClassName)
		);
		this.getBreadCrumbCodex();
	}


  getBreadCrumbCodex = async (e) => {
    const tokenAPI = localStorage.getItem("tokenAPI");
    let getThisDocId = this.getThisDocId();
    const self = this;
  
    let linkGet_BCC = global.apiLink+`api/codex/codex/breadcrumb-codex?token=${tokenAPI}&id=`+getThisDocId;
    let headers = new Headers();
    headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
    const api_url_BreadCrumbCodex = await 
    fetch(linkGet_BCC,{
        headers:headers
    });
    const jsonBCC = await api_url_BreadCrumbCodex.json();
    if(jsonBCC.status !== 200){
        alert("Не верные данные авторизации");
        localStorage.removeItem('tokenAPI');
        return false;
    }
    let BreadCrumbCodex = await jsonBCC.payload;

    BreadCrumbCodex = BreadCrumbCodex.reverse();
    BreadCrumbCodex.forEach(function(item, index, array) {
      if(index !== 0){
        var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"] .fa-folder-open');
        if(element === null){
          self.expandParent(BreadCrumbCodex[index]);
        }
      }
    });
    BreadCrumbCodex.forEach(function(item, index, array) {
      if(index !== 0){
        var element = document.querySelector('li[data-docid="'+BreadCrumbCodex[index]+'"]');
        if(element !== null){
          element.classList.add('active');
        }
      }
    });
        var element = document.querySelector('li[data-docid="'+getThisDocId+'"]');
        if(element !== null){
          element.classList.add('active');
        }
    return BreadCrumbCodex;
  }

  isfolderbtn = (element) => {
      this.expandParent(element.element.id);
	  if(this.state.displayChild.indexOf(element.element.id) === -1){
		  ReactDOM.render(
			<>
			</>,
			document.getElementById('submenu_Id' + element.element.id)
		);
	  }else{
		  this.getCodexTree(element.element.id);
	  }
      
  }

  getChildButton = (parentId, classname_str = "", tagName = "i") => {
    return (
      <>
        { React.createElement(tagName,{
          className: classname_str+'fas fa-plus-circle ml-2 resource_add_child',
          dataPid: parentId,
          title: 'Добавить дочерний ресурс',
          onClick: () => this.resourceAddChild(parentId)
        },) }
      </>
    );
  }

  render(){
    
    this.getCodexTree();
    //this.getPlenumTree();
    
      return (
          <div className="col-md-4">
              <div className="row">
                  <div className="d-none"><button name="refreshtree" onClick={() =>{this.setState({refreshtime: this.state.refreshtime + 1})}}></button></div>
                    <ul className="mb-1 pb-0">
					            <li>
                        {React.createElement('i',{className: 'fas fa-globe'},)}
                        <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title" href={"/codex/"} data-toggle="tooltip" data-placement="top" title={"Кодексы"}>Кодексы</a>
                        {this.getChildButton(0)}
                      </li>
					          </ul>
                  <div id="codex-tree-container" className="col-md-11 ml-3"><img src="/assets/images/826.gif" width="40px" alt="Загрузка..."/></div>
              </div>
          </div>
      );
  }

  _renderChildren =  async (docid)  => {
		const tokenAPI = localStorage.getItem("tokenAPI");
		let linkGet = global.apiLink+`api/codex/codex/list?token=${tokenAPI}&recursive=1&pid=`+docid+`&exclude=s`;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
		const api_url = await fetch(linkGet,{
		    headers:headers
        });
		const json = await api_url.json();
		if(json.status !== 200){
			alert("Не верные данные авторизации getCodexTree("+docid+")");
			localStorage.removeItem('tokenAPI');
			return false;
		}
    let subMenu = await json.payload.list;
    
    const result = subMenu.nodes.map(el => {
    let linkHref = el.element.alias+"_"+el.element.id;
      return ( <li 
                  key={el.element.id} 
                  className={el.element.id === this.state.displayChild && "active"} 
                  data-docid={el.element.id}
              >
                  {el.nodes && (this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                  {el.nodes && (this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
                  {!el.nodes && React.createElement('i',{className: 'fs-fa-empty mr-2'},)}
                  {!el.nodes && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
                  {el.nodes && React.createElement('i',{className: 'fas fa-book mr-2'},)}
                  <a className="resource_title" href={"/codex/"+linkHref} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
                  
                  {(this.state.displayChild.indexOf(el.element.id) !== -1) &&
                      el.nodes &&
                      this._renderChildren(el.nodes)}
              </li>
			  )
      });

    return <ul>{result}</ul>;
  };

  expandParent = id => {
      let displayChild = this.state.displayChild;
      if(displayChild.length === 0){
          displayChild[0]=id;
      }else if(displayChild.length > 0){
          let indx = displayChild.indexOf(id)
          if(indx === -1 ){
              displayChild[displayChild.length]=id;
          }else{
              displayChild.splice(indx, 1);
          }
      }else{
          displayChild = [];
      }/**/
          this.setState({ displayChild: displayChild });
  };

}

export class TreeSituations extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
          refreshtime: 1,
          data: {},
          displayChild: [],
		  AllLoaded: []
      };
    }
  
  
    resourceAddChild = (parent_id) => {
      document.getElementById('resource-create').style.display = "block";
      document.getElementById('form_resource_parentid').value = parent_id;
      
      document.getElementById('form-resource-create').removeAttribute("data-resourceid");
      document.getElementById('form_resource_title').value = "";
      document.getElementById('form_resource_name').value = "";
      document.getElementById('form_tags').value = "";
      document.getElementById('form_resource_alias').value = "";
      //document.getElementById('form_resource_description').value = "";
      document.getElementById('form_resource_active').value = 1;
        document.getElementById('form_resource_content').innerHTML = "";
        try {
          window.tinymce.get('form_resource_content').setContent("");
        } catch (err) {
            console.log(err);
        }
        window.refreshTinyMCEInit();
      document.getElementById('form_codex_id').value = "";
      document.getElementById('form_resource_title').focus();
    }

    createMarkup = (s) => {
      return {__html: s};
    }
    
    resourceRemove = async (resourceid) =>{
      var Realy = window.confirm("Действительно удалить ресурс?");
      if(Realy === false)return false;
        const tokenAPI = localStorage.getItem("tokenAPI");
        let uri = global.apiLink+`api/situation/situation/remove?id=${resourceid}&token=${tokenAPI}`;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let api_url = await fetch(uri, {
            method: 'GET',
            //body: Data,
            headers:headers
        });
        let json = await api_url.json();
        if(json.status !== 200){
            console.log(json);
            alert("Не верные данные авторизациия");
            localStorage.removeItem('tokenAPI');
        }else{
            //var templatetotvData = await json.payload;
            //console.log(templatetotvData);
            alert("Ресурс удалён");
        }
    }

    getSituationTree = async (docid = 0) => { 

      const tokenAPI = localStorage.getItem("tokenAPI");
      let linkGet = global.apiLink + `api/situation/situation/list?token=${tokenAPI}&recursive=1&pid=`+docid+`&lvl=1`;
      let headers = new Headers();
      headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
      const api_url = await fetch(linkGet,{
          headers:headers
      });
      const json = await api_url.json();
      
      if(json.status !== 200){
        alert("Не верные данные авторизации getCodexTree("+docid+")");
        localStorage.removeItem('tokenAPI');
        return false;
      }

      console.log(json);
      this.state.data = await json.payload.list;
      this.state.AllLoaded[docid] = await json.payload.list;
  
      var targetClassName = 'codex-tree-container';
      if(docid !== 0){
        targetClassName = 'submenu_Id' + docid;
      }
      const result = await this.state.data.nodes.map(el => {
        let isActive = "";
        if(this.state.displayChild.indexOf(el.element.id) !== -1){isActive = "active";}
        return (
          <li 
            key={el.element.id}
            className={isActive + "resourceid"+el.element.id}
            data-docid={el.element.id}
          >
            {React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
            <a className="resource_title" onClick={() => this.resourceEdit(el.element.id)} data-toggle="tooltip" data-placement="top" title={el.element.title} > <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
            {React.createElement('i',{className: 'fas fa-minus-circle ml-2 resource_remove', dataPid: el.element.id, title: 'Удалить', onClick: () => this.resourceRemove(el.element.id)},)}
            <div id={"submenu_Id"+el.element.id}></div>
            
          </li>
        )
      });
      
      ReactDOM.render(
        <ul>
          {result}
        </ul>,
        document.getElementById(targetClassName)
      );
      /* this.getBreadCrumbCodex(); /**/
    }
  

    resourceGet = async (resourceid) => {
      const tokenAPI = localStorage.getItem("tokenAPI");
      let headers = new Headers();
      headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
      const api_url = await 
      fetch(global.apiLink+`api/situation/situation/get?token=${tokenAPI}&id=${resourceid}`,{
          headers:headers
      });
      const json = await api_url.json();
      if(json.status !== 200){
          alert("Не верные данные авторизации");
          localStorage.removeItem('tokenAPI');
          return false;
      }
      var resourceData = await json.payload;
      if(resourceData){
        return resourceData;  
      }
      return false;
  }


    resourceEdit = async (resourceid) => {
      let swlwctwdMenuElement = document.querySelectorAll("#codex-tree-container li");
      for(let i=0;i<swlwctwdMenuElement.length;i++){
        swlwctwdMenuElement[i].removeAttribute("style");
      }
      swlwctwdMenuElement = document.querySelector("li.resourceid"+resourceid+"");
      if(swlwctwdMenuElement !== null)swlwctwdMenuElement.style.fontWeight = 600;
      document.getElementById("codex-tvlist-container").innerHTML = "";
        document.getElementById('resource-create').style.display = "block";
        setTimeout(()=>{window.refreshTinyMCEInit();}, 100);
        var resourceData = await this.resourceGet(resourceid);
        console.log(resourceData);
        if(resourceData){
            document.getElementById('form-resource-create').setAttribute("data-resourceid", resourceid);
            if(resourceData.title !== undefined){
                document.getElementById('form_resource_title').setAttribute("data-value", resourceData.title);
                document.getElementById('form_resource_title').value = resourceData.title;
            }
            if(resourceData.alias !== undefined){
                document.getElementById('form_resource_alias').setAttribute("data-value", resourceData.alias);
                document.getElementById('form_resource_alias').value = resourceData.alias;
            }
            /*if(resourceData.description !== undefined){
                document.getElementById('form_resource_description').setAttribute("data-value", resourceData.description);
                document.getElementById('form_resource_description').value = resourceData.description;
            }/**/
            if(resourceData.name !== undefined){
                document.getElementById('form_resource_name').setAttribute("data-value", resourceData.name);
                document.getElementById('form_resource_name').value = resourceData.name;
            }
            if(resourceData.tags !== undefined){
                document.getElementById('form_tags').setAttribute("data-value", resourceData.tags);
                document.getElementById('form_tags').value = resourceData.tags;
            }
            if(resourceData.parent_id !== undefined)document.getElementById('form_resource_parentid').value = resourceData.parent_id;
            if(resourceData.active !== undefined){
              document.getElementById('form_resource_active').value = parseInt(resourceData.active);
            }
            if(resourceData.codex_id !== undefined)document.getElementById('form_codex_id').value = resourceData.codex_id;
            if(resourceData.content !== undefined){
                document.getElementById('form_resource_content').innerHTML = resourceData.content;
                try {
                  window.tinymce.get('form_resource_content').setContent(resourceData.content);
                } catch (err) {
                    console.log(err);
                }
                window.refreshTinyMCEInit();
                console.log(resourceData.content);
            }
        }   
    }


    render(){ 
      this.getSituationTree();
        return ( 
            <div className="col-md-4">
                <div className="row">
                    <div className="d-none"><button name="refreshtree" onClick={() =>{this.setState({refreshtime: this.state.refreshtime + 1})}}></button></div>
                      <ul className="mb-1 pb-0">
                        <li>
                          {React.createElement('i', { className: 'fas fa-globe' })}
                          <span className="resource_title" data-toggle="tooltip" data-placement="top" title="Рут" /> <a className="resource_title" href={"/interface/situation/"} data-toggle="tooltip" data-placement="top" title="Ситуации"> Ситуации </a>
                          {React.createElement('i',{className: 'fas fa-plus-circle ml-2 resource_add_child', dataPid: 0, title: 'Добавить дочерний ресурс', onClick: () => this.resourceAddChild(0)},)}
                        </li>
                      </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3"><img src="/assets/images/826.gif" width="40px" alt="Загрузка..."/></div>
                 </div>
            </div>
        );
    }
  }


export class TreeQa extends React.Component {
  constructor(props) {
      super(props);
  
      this.state = {
          refreshtime: 1,
          data: {},
          displayChild: [],
		  AllLoaded: []
      };
    }
  
  
    resourceAddChild = () => {
      document.getElementById('resource-create').style.display = "block";
      //document.getElementById('form_resource_parentid').value = parent_id;
      
      document.getElementById('form-resource-create').removeAttribute("data-resourceid");
      document.getElementById('form_resource_title').value = "";
      document.getElementById('form_resource_tags').value = "";
      //document.getElementById('form_resource_alias').value = "";
      //document.getElementById('form_resource_active').value = 1;
        document.getElementById('form_resource_content').innerHTML = "";
        try {
          window.tinymce.get('form_resource_content').setContent("");
        } catch (err) {
            console.log(err);
        }
        window.refreshTinyMCEInit();
      document.getElementById('form_codex_id').value = "";
      document.getElementById('form_resource_title').focus();
    }

    createMarkup = (s) => {
      return {__html: s};
    }
    
    resourceRemove = async (resourceid) =>{
      var Realy = window.confirm("Действительно удалить ресурс?");
      if(Realy === false)return false;
        const tokenAPI = localStorage.getItem("tokenAPI");
        let uri = global.apiLink+`api/qa/qa/remove?id=${resourceid}&token=${tokenAPI}`;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let api_url = await fetch(uri, {
            method: 'GET',
            //body: Data,
            headers:headers
        });
        let json = await api_url.json();
        if(json.status !== 200){
            console.log(json);
            alert("Не верные данные авторизациия");
            localStorage.removeItem('tokenAPI');
        }else{
            //var templatetotvData = await json.payload;
            //console.log(templatetotvData);
            alert("Ресурс удалён");
        }
    }

    getQaTree = async (docid = 0) => { 

      const tokenAPI = localStorage.getItem("tokenAPI");
      let linkGet = global.apiLink + `api/qa/qa/list-articles?token=${tokenAPI}`;
      let headers = new Headers();
      headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
      const api_url = await fetch(linkGet,{
          headers:headers
      });
      const json = await api_url.json();
      
      if(json.status !== 200){
        alert("Не верные данные авторизации getCodexTree("+docid+")");
        localStorage.removeItem('tokenAPI');
        return false;
      }

      console.log(json);
      this.state.data = await json.payload.list;
      this.state.AllLoaded[docid] = await json.payload.list;
  
      var targetClassName = 'codex-tree-container';
      if(docid !== 0){
        targetClassName = 'submenu_Id' + docid;
      }
      const result = await this.state.data.nodes.map(el => {
        let isActive = "";
        if(this.state.displayChild.indexOf(el.element.id) !== -1){isActive = "active";}
        return (
          <li 
            key={el.element.id}
            className={isActive + "resourceid"+el.element.id}
            data-docid={el.element.id}
          >
            {(el.element.type_node != "article" && this.state.displayChild.indexOf(el.element.id) === -1) && React.createElement('i',{className: 'far fa-folder isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
            {(this.state.displayChild.indexOf(el.element.id) !== -1) && React.createElement('i',{className: 'far fa-folder-open isfolder-btn mr-2', onClick: () => this.isfolderbtn(el)},)}
            {el.element.type_node == "article" && React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
            {el.element.type_node != "article" && React.createElement('i',{className: 'fas fa-book mr-2'},)}
            <a className="resource_title2" data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
            {React.createElement('a',{className: 'fa fa-link ml-2', style:{textDecoration:'none'}, target: '_blank', href: global.feLink+"codex/"+el.element.link},)}
            <div id={"submenu_Id"+el.element.id}></div>
            
          </li>
        )
      });
      
      ReactDOM.render(
        <ul>
          {result}
        </ul>,
        document.getElementById(targetClassName)
      );
    }

    

  isfolderbtn = (element) => {
    this.expandParent(element.element.id);
  if(this.state.displayChild.indexOf(element.element.id) === -1){
    ReactDOM.render(
    <>
    </>,
    document.getElementById('submenu_Id' + element.element.id)
  );
  }else{
    this.getQaTreeSec(element.element.id);
  }
    
}

expandParent = id => {
    let displayChild = this.state.displayChild;
    if(displayChild.length === 0){
        displayChild[0]=id;
    }else if(displayChild.length > 0){
        let indx = displayChild.indexOf(id)
        if(indx === -1 ){
            displayChild[displayChild.length]=id;
        }else{
            displayChild.splice(indx, 1);
        }
    }else{
        displayChild = [];
    }/**/
        this.setState({ displayChild: displayChild });
};


    getQaTreeSec = async (docid = 0) => { 

      const tokenAPI = localStorage.getItem("tokenAPI");
      let linkGet = global.apiLink + `api/qa/qa/list?token=${tokenAPI}&recursive=1&codex_id=`+docid+`&lvl=1`;
      let headers = new Headers();
      headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
      const api_url = await fetch(linkGet,{
          headers:headers
      });
      const json = await api_url.json();
      
      if(json.status !== 200){
        alert("Не верные данные авторизации getCodexTree("+docid+")");
        localStorage.removeItem('tokenAPI');
        return false;
      }

      console.log(json);
      this.state.data = await json.payload.list;
      this.state.AllLoaded[docid] = await json.payload.list;
  
      var targetClassName = 'codex-tree-container';
      if(docid !== 0){
        targetClassName = 'submenu_Id' + docid;
      }
      const result = await this.state.data.nodes.map(el => {
        let isActive = "";
        if(this.state.displayChild.indexOf(el.element.id) !== -1){isActive = "active";}
        return (
          <li 
            key={el.element.id}
            className={isActive + "resourceid"+el.element.id}
            data-docid={el.element.id}
          >
            {React.createElement('i',{className: 'far fa-sticky-note mr-2'},)}
            <a className="resource_title" onClick={() => this.resourceEdit(el.element.id)} data-toggle="tooltip" data-placement="top" title={el.element.title}> <span dangerouslySetInnerHTML={this.createMarkup(el.element.title)} /></a> ({el.element.id})
            {React.createElement('i',{className: 'fas fa-minus-circle ml-2 resource_remove', dataPid: el.element.id, title: 'Удалить', onClick: () => this.resourceRemove(el.element.id)},)}
            <div id={"submenu_Id"+el.element.id}></div>
            
          </li>
        )
      });
      
      ReactDOM.render(
        <ul>
          {result}
        </ul>,
        document.getElementById(targetClassName)
      );
    }
  /**/

    resourceGet = async (resourceid) => {
      const tokenAPI = localStorage.getItem("tokenAPI");
      let headers = new Headers();
      headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
      const api_url = await 
      fetch(global.apiLink+`api/qa/qa/get?token=${tokenAPI}&id=${resourceid}`,{
          headers:headers
      });
      const json = await api_url.json();
      if(json.status !== 200){
          alert("Не верные данные авторизации");
          localStorage.removeItem('tokenAPI');
          return false;
      }
      var resourceData = await json.payload;
      if(resourceData){
        return resourceData;  
      }
      return false;
  }


    resourceEdit = async (resourceid) => {

      let swlwctwdMenuElement = document.querySelectorAll("#codex-tree-container li");
      for(let i=0;i<swlwctwdMenuElement.length;i++){
        swlwctwdMenuElement[i].removeAttribute("style");
      }
      swlwctwdMenuElement = document.querySelector("li.resourceid"+resourceid+"");
      if(swlwctwdMenuElement !== null)swlwctwdMenuElement.style.fontWeight = 600;
      document.getElementById("codex-tvlist-container").innerHTML = "";
        document.getElementById('resource-create').style.display = "block";
        setTimeout(()=>{window.refreshTinyMCEInit();}, 100);
        var resourceData = await this.resourceGet(resourceid);
        if(resourceData){
          
            document.getElementById('form-resource-create').setAttribute("data-resourceid", resourceid);
            if(resourceData.title !== undefined){
                document.getElementById('form_resource_title').setAttribute("data-value", resourceData.title);
                document.getElementById('form_resource_title').value = resourceData.title;
            }
            if(resourceData.tags !== undefined){
                document.getElementById('form_resource_tags').setAttribute("data-value", resourceData.tags);
                document.getElementById('form_resource_tags').value = resourceData.tags;
            }
            /*if(resourceData.title !== undefined){
                document.getElementById('form_resource_alias').setAttribute("data-value", resourceData.alias);
                document.getElementById('form_resource_alias').value = resourceData.alias;
            }
            if(resourceData.parent_id !== undefined)document.getElementById('form_resource_parentid').value = resourceData.parent_id;
            if(resourceData.active !== undefined){
              document.getElementById('form_resource_active').value = parseInt(resourceData.active);
            }/**/
            if(resourceData.codex_id !== undefined)document.getElementById('form_codex_id').value = resourceData.codex_id;
            if(resourceData.content !== undefined){
                document.getElementById('form_resource_content').innerHTML = resourceData.content;
                try {
                  window.tinymce.get('form_resource_content').setContent(resourceData.content);
                } catch (err) {
                    console.log(err);
                }
                window.refreshTinyMCEInit();
                console.log(resourceData.content);
            }
        }   
    }


    render(){ 
      this.getQaTree();
        return ( 
            <div className="col-md-4">
                <div className="row">
                    <div className="d-none"><button name="refreshtree" onClick={() =>{this.setState({refreshtime: this.state.refreshtime + 1})}}></button></div>
                      <ul className="mb-1 pb-0">
                        <li>
                          {React.createElement('i', { className: 'fas fa-globe' })}
                          <span className="resource_title" data-toggle="tooltip" data-placement="top" title="Рут" /> <a className="resource_title" href={"/interface/qa/"} data-toggle="tooltip" data-placement="top" title="Вопрос-ответ"> Вопрос-ответ </a>
                          {React.createElement('i',{className: 'fas fa-plus-circle ml-2 resource_add_child', dataPid: 0, title: 'Добавить дочерний ресурс', onClick: () => this.resourceAddChild(0)},)}
                        </li>
                      </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3"><img src="/assets/images/826.gif" width="40px" alt="Загрузка..."/></div>
                 </div>
            </div>
        );
    }
  }
class App extends React.Component {


  render(){
    const tokenApi = localStorage.getItem("tokenAPI");
    if( tokenApi === undefined || !tokenApi ){
      return (
        <Signin />
      );
    }else{
      return (
        <BrowserRouter>
          <Rightsidebar /> 
          <HeaderT /> 
          <BodyT />  
          <Clock />
        </BrowserRouter>
      );
    }
  }
}

export default App;
