import React from 'react';

class FormRegions extends  React.Component{

    constructor(props) {
        super(props);
        this.state = {
            id:this.props.match.params.id,
            name:''
        };
    }

    save(e){
        e.preventDefault();
        const tokenAPI = localStorage.getItem("tokenAPI");
        let formData = new FormData(e.target);
        let url = global.apiLink+"/regions/region/edit";
        fetch(url,{
            method:'POST',
            body:formData
        }).then((result)=>{
            // if(this.props.updateTree){
           // this.props.updateTree(formData.get('parent_id'));
            //  }

        });
    }
    render() {
        return(
            <div>
                <form id="form-resource-create" onSubmit={ (e) => this.save(e) }>
                    <input type="hidden" id="id-form-edit" name="id" value={this.state.id}/>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm" >Сохранить</button>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Название региона</label>
                                <input type="text" className="form-control" name="name" id="form_resource_title"  value={this.state.name} onChange={e => this.setState({ name: e.target.value })} placeholder="Название региона" />

                            </div>
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}
export default FormRegions;