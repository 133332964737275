import React from 'react';
import Toppanel from "../top_panel";
import ListUsers from "./list";
import ReactDOM from 'react-dom';
import EditUser from "./edit";
class UsersComponent extends React.Component{

    constructor(props) {
        super(props);
        let id =0;
        if (props.match.params.id) {
            id = props.match.params.id;


        }

        this.state = {
            userId:id
        };
    }
    componentDidMount() {
        if(this.state.userId){
            ReactDOM.render(<EditUser id={this.state.userId}/>,document.getElementById('work-area'));
        }
    }
    render() {

        return(
            <div>
                <Toppanel title="Управление Пользователями"/>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <ListUsers idcurrent={this.state.userId}/>
                            <div id="work-area" className="col-md-8 float-right">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UsersComponent;