import React from 'react';
import ReactDOM from 'react-dom';
import BodyT from "./body";
import HeaderT from "./header";
import Rightsidebar from "./rightsidebar/rightsidebar";
import Clock from "./clock";
import { BrowserRouter } from 'react-router-dom';


class Signin extends React.Component {

    gettingSingin = async (e) => {
        e.preventDefault();
        const login = e.target.elements.inputusername.value;
        const password = e.target.elements.inputPassword.value;

        const api_url = await 
        fetch(global.apiLink+`/api/token?login=${login}&password=${password}`);
        const data = await api_url.json();
        if(data.status !== 200){
            alert("Не верные данные авторизации");
            return false;
        }
        localStorage.setItem("tokenAPI", data.payload.token);
        ReactDOM.render(
            <BrowserRouter>
                <Rightsidebar /> 
                <HeaderT /> 
                <BodyT />  
                <Clock />
            </BrowserRouter>,
            document.getElementById('root')
        );
    }

    render(){
        return (
            <div>
                <form id="form_auth" className="form-signin" onSubmit={this.gettingSingin}>
                    <div className="text-center mb-4">
                        <img className="mb-4" src="/assets/images/logo.png" alt="" width="120"/>
                        <h1 className="h3 mb-3 font-weight-normal">Авторизация</h1>
                    </div>
                    
                    <div className="form-label-group">
                        <input type="text" name="inputusername" className="form-control" placeholder="Пользователь" required="" autoFocus=""/>
                        <label htmlFor="inputusername">Пользователь</label>
                    </div>
                    
                    <div className="form-label-group">
                        <input type="password" name="inputPassword" className="form-control" placeholder="Пароль" required=""/>
                        <label htmlFor="inputPassword">Пароль</label>
                    </div>
                    
                    <button className="btn btn-lg btn-primary btn-block" type="submit">Авторизоваться</button>
                    <p className="mt-5 mb-3 text-muted text-center">© 2020 «PROGRAMLAB»</p>
                </form>
            </div>
        );
    }
}

export default Signin;