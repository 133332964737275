import React from 'react';
import DocsResourceviewerForm from "./docsresourceviewerform";
import ReactDOM from 'react-dom';
import Childlist from "./childlist";
class TreeDocs extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            refreshtime: 1,
            data: {},
            displayChild: [],
            AllLoaded: [],
            countItems:0,
            elements:[]
        };
    }
    componentDidMount() {
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(global.apiLink + 'doc/document/list?with_child=true',{
            headers:headers
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.payload) {
                        let elementsArr;
                        this.setState({
                            countItems: result.payload.length,
                            elements: result.payload
                        });
                    }
                },
                (error) => {
                    alert('something went wrong');
                }
            )

    }
    getChildButton = (parentId, classname_str = "", tagName = "i") => {
        return (
            <>
                { React.createElement(tagName,{
                    className: classname_str+'fas fa-plus-circle ml-2 resource_add_child',
                    dataPid: parentId,
                    title: 'Добавить дочерний ресурс',
                    onClick: () => this.editAction(parentId)
                },) }
            </>
        );
    }

    editAction = async (id,parent_id=0) => {
        ReactDOM.unmountComponentAtNode(document.getElementById('docs-work-area'))
        ReactDOM.render(<DocsResourceviewerForm updateTree={this.handlechangeForm} id={id} parent_id={parent_id}/>,document.getElementById('docs-work-area'));
    }
    handlechangeForm = async (idParent) =>{
        //ReactDOM.unmountComponentAtNode(document.getElementById('sub-elements-'+idParent));
        this.listChild(idParent,true).then((result) =>{
                this.forceUpdate();
            }
        );
    }
    listChild = async  (idParent,reDraw = false) =>{
        let idElement = "sub-elements-"+idParent;
        let element =  document.getElementById(idElement);
        if(!element){
            return false;
        }
        let elements = this.state.elements
        if(!elements[idParent].childs || reDraw){
            let headers = new Headers();
            headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
            let url = global.apiLink+'/doc/document/list?parent_id='+idParent;
            let api_url = await fetch(url,{
                headers:headers
            });
            let result = await api_url.json();
            let listData = result.payload;
            if(listData){
                elements[idParent].childs = [];
                Object.keys(listData).map(id=>{
                    elements[idParent].childs.push({id:id,name:listData[id].name});
                });

            }
        }
        else{
            delete elements[idParent].childs;
        }

        this.setState({elements:elements});

    }

    render() {

        const {countItems, elements} = this.state;
        const treeElements =  Object.keys(elements).map(id =>
            <li key={id}>
                <i className="far fa-folder isfolder-btn mr-2" aria-hidden="true" onClick={()=>this.listChild(id)}/>
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <a className="resource_title" data-toggle="tooltip" data-placement="top" onClick={() => this.editAction(id)}
                   title="Уголовный кодекс Российской Федерации">
                    <span>{elements[id].name}</span>
                </a>
                <i className="fas fa-plus-circle ml-2 resource_add_child" onClick={()=>this.editAction(0,id)} title="Добавить дочерний ресурс"
                   aria-hidden="true"/>
                <div id={['sub-elements-'+id].join()}>

                    {elements[id].childs ?   <Childlist id={id} handlechangeForm={this.handlechangeForm} childs={elements[id].childs}  /> :null }

                </div>
            </li>
        );
        return(
            <div className="col-md-4">
                <div className="row">
                    <div className="d-none"><button name="refreshtree" onClick={() =>{this.setState({refreshtime: this.state.refreshtime + 1})}}></button></div>
                    <ul className="mb-1 pb-0">
                        <li>
                            {React.createElement('i',{className: 'fas fa-globe'},)}
                            <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title"  data-toggle="tooltip" data-placement="top" title={"Документы"}>Документы</a>
                            {this.getChildButton(0)}
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3">
                        <ul>
                            {treeElements}
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}

export default TreeDocs;