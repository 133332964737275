import React from 'react';

class Toppanel extends React.Component {

    render(){
        
        return (
            <div id="header" >
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mt-60">{this.props.title}</h1>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Toppanel;