import React from 'react';
import Childlist from "../docs/childlist";
import Subjects from "./Subjects";
import ReactDOM from "react-dom";
import Pagination from "../../elements/Pagination";
import {Link} from "react-router-dom";

class TreeInformation extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            elements:[],
            countItems:0
        }
    }
    componentDidMount(){

        fetch(global.apiLink + 'regions/region/list')
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.payload) {
                        let elementsArr;
                        console.log(Object.keys(result.payload).length);
                        this.setState({
                            countItems:  Object.keys(result.payload.list).length,
                            countAllItems:result.payload.element_count,
                            elements: result.payload.list
                        });
                        if(result.payload.element_count >0 ){
                            let count_news = parseInt(result.payload.element_count);
                            ReactDOM.render(<Pagination totalRecords={count_news} pageLimit={20} onPageChanged={this.onPageChanged}/>,document.getElementById('paginate'))
                        }
                    }
                },
                (error) => {
                    alert('something went wrong ');
                }
            )

    }
    onPageChanged = paginationData =>{
        let currentPage = paginationData.currentPage;
        let url = global.apiLink +'regions/region/list?limit=20&page='+currentPage;
        fetch(url).then(res=>res.json())
            .then((result)=>{
                const  regions = result.payload.list;
                this.setState({
                    elements:regions,
                    countItems:Object.keys(result.payload.list).length
                });
            });
    }

    getChildButton = (parentId, classname_str = "", tagName = "i") => {
        return (
            <>
                { React.createElement(tagName,{
                    className: classname_str+'fas fa-plus-circle ml-2 resource_add_child',
                    dataPid: parentId,
                    title: 'Добавить регион',
                    onClick: () => this.addRegions()
                },) }
            </>
        );
    }
    addRegions(){
        window.location = '/interface/inf/regions/0';
    }
    listRegions(){
        const {countItems, elements} = this.state;
        if(!countItems){
            return false;
        }
        const treeElements =  Object.keys(elements).map(id =>
            <li key={id}>
                <i className="far fa-folder isfolder-btn mr-2" aria-hidden="true" />
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <Link className="resource_title" data-toggle="tooltip" data-placement="top"
                   title="Уголовный кодекс Российской Федерации">
                    <span>{elements[id].name}</span>
                </Link>
                <i className="fas fa-plus-circle ml-2 resource_add_child" onClick={()=>this.editAction(0,id)} title="Добавить дочерний ресурс"
                   aria-hidden="true"/>
                <div id={['sub-elements-'+id].join()}>



                </div>
            </li>
        );
        return treeElements;

    }

    render() {
        const {countItems, elements} = this.state;
        if(!countItems){
            return false;
        }
        const treeElements =  Object.keys(elements).map(id =>
            <li key={id}>
                <i className="far fa-folder isfolder-btn mr-2" aria-hidden="true" />
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <Link className="resource_title" data-toggle="tooltip" data-placement="top"
                   title="Уголовный кодекс Российской Федерации">
                    <span>{elements[id].name}</span>
                </Link>
                <Link to={'/interface/inf/subject/0?region_id='+id}>
                <i className="fas fa-plus-circle ml-2 resource_add_child"  title="Добавить дочерний ресурс"
                   aria-hidden="true" />
                </Link>
                <div id={['sub-elements-'+id].join()}>
                    {Array.isArray(elements[id].subjects)? <Subjects list={elements[id].subjects} region_id={id}/> :''}


                </div>
            </li>
        );
        return(
            <div className="col-md-4">
                <div className="row">
                    <ul className="mb-1 pb-0">
                        <li>
                            {React.createElement('i',{className: 'fas fa-globe'})}
                            <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title"  data-toggle="tooltip" data-placement="top" title={"Документы"}>Справочная информация</a>
                            {this.getChildButton(0)}
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3">
                        <ul>
                            {treeElements}
                        </ul>

                    </div>
                    <div id="paginate">
                    </div>
                </div>

            </div>
        );

    }
}

export default TreeInformation;