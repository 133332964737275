import React from 'react';
import Toppanel from "../top_panel";
import List from "./List";
import ReactDOM from "react-dom";
import Edit from "./Edit";



class Permissions extends React.Component{

    constructor(props) {
        super(props);
        let id=0;
        if (props.match.params.id) {
            id = Number.parseInt(props.match.params.id);
        }
        this.state = {
            id:id
        };
    }
    componentDidMount() {
        if(Number.isInteger(this.state.id)){
            ReactDOM.render(<Edit id={this.state.id}/>, document.getElementById('work-area'));
        }
    }

    render() {
        return (
            <div>
                <Toppanel title="Управление Разрешениями"/>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <List/>
                            <div id="work-area" className="col-md-8 float-right">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Permissions;