import React from 'react';
import ReactDOM from 'react-dom';
import Toppanel from '../top_panel';

import {TreeSituations} from '../../../App'

import "../../../global.js";
let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
export class WorkAreaSituations extends React.Component {




    resourceGetTemplateTVs = async () => {
        let resourceid = document.getElementById('form-resource-create').getAttribute("data-resourceid");
        if(resourceid === undefined || resourceid === null)return false;
        let templateid = document.getElementById("form_resource_id_template").value;
        const tokenAPI = localStorage.getItem("tokenAPI");
        const uri = global.apiLink+`api/templates/templatetotv/list?token=${tokenAPI}&templateid=${templateid}`;

        const api_url = await 
        fetch(uri,{
            headers:headers
        });
        const json = await api_url.json();
        if(json.status !== 200){
            alert("Не верные данные авторизации");
            localStorage.removeItem('tokenAPI');
            return false;
        }
        var templatetotvData = await json.payload;
        
        var frit = document.getElementById("codex-tvlist-container");
        frit.innerHTML = "";
        if(templatetotvData){
            templatetotvData.array.forEach(async element => {
                var newTVwrapper = document.createElement("div");
                newTVwrapper.name = element.alias;
                if(element.type == 1){ // Тип переменной список элементов
                    var newTV = document.createElement("textarea");
                    newTV.name = element.alias;
                    newTV.classList.add("mig_tv_item");
                    newTV.value = element.default_value;
                    newTV.style.display = "none";
                    let uriget = global.apiLink+`api/templates/templateariable/get?token=${tokenAPI}&resourceid=${resourceid}&tvid=${element.id_tv}`;
                    let api_urlget = await fetch(uriget,{
                        headers:headers
                    });
                    let jsonget = await api_urlget.json();
                    if(jsonget){
                        newTV.value = await jsonget.payload.value;
                        newTV.innerHTML = await jsonget.payload.value;
                        //console.log(newTV.value);
                        newTV.setAttribute("data-tvid", element.id_tv);
                        var windowtitle = "Добавление элемента";
                        var buttontitle = "Добавить элемент";
                        var Variables = "";
                        if(element.config !== undefined ){
                            var TVconfig = JSON.parse(element.config);
                            if(TVconfig){
                                windowtitle = TVconfig.title;
                                buttontitle = TVconfig.buttontitle;
                                newTV.setAttribute("data-config", element.config);
                                TVconfig.variables.forEach((el) => {

                                    if(el.type == "textarea"){
                                        Variables += '<div class="form-group">'+
                                        '<label for="mig_'+el.name+'">'+el.label+':</label>'+
                                        '<textarea class="form-control" id="mig_'+el.name+'" name="'+el.name+'"></textarea>'+
                                        '</div>';
                                    }
                                    if(el.type == "richtext"){
                                        Variables += '<div class="form-group">'+
                                        '<label for="mig_'+el.name+'">'+el.label+':</label>'+
                                        '<textarea class="form-control richtext" id="mig_'+el.name+'" name="'+el.name+'"></textarea>'+
                                        '</div>';
                                    }
                                    
                                });
                            }
                        }
                        
                        var modalWindow = '<button type="button" id="Modal'+element.alias+'_btn" class="btn btn-primary" data-toggle="modal" data-target="#Modal'+element.alias+'">'+buttontitle+'</button>';

                        modalWindow += '<div class="modal fade" id="Modal'+element.alias+'" tabindex="-1" role="dialog" aria-labelledby="ModalLabel'+element.alias+'" aria-hidden="true">'+
                        '  <div class="modal-dialog modal-lg" role="document">'+
                        '    <div class="modal-content">'+
                        '      <div class="modal-header">'+
                        '        <h5 class="modal-title" id="ModalLabel'+element.alias+'">'+windowtitle+'</h5>'+
                        '        <button type="button" class="close mig_close" data-dismiss="modal" aria-label="Close">'+
                        '          <span aria-hidden="true">&times;</span>'+
                        '        </button>'+
                        '      </div>'+
                        '      <div class="modal-body">'+
                        '        <form id="mig_tv_'+element.alias+'">'+Variables+'</form>'+
                        '      </div>'+
                        '      <div class="modal-footer">'+
                        '        <div id="applybtncontainer"></div>'+
                        '      </div>'+
                        '    </div>'+
                        '  </div>'+
                        '</div>';
                        newTVwrapper.innerHTML = (modalWindow + newTV.outerHTML);
                        frit.appendChild(newTVwrapper);
                        
                        ReactDOM.render(
                            <div>
                                <button type="button" className="btn btn-secondary mr-3" onClick={()=>this.clearTinyEditor(element.alias)}  data-dismiss="modal">Отмена</button>
                                <button type="button" onClick={()=>this.applyTvChanges(element.alias)} className="btn btn-primary mig_apply_">Принять</button>
                            </div>
                            ,document.getElementById("applybtncontainer")
                        );
                        this.formationTable();
                    }
                }
            });
        }   
    }

    clearTinyEditor = (alias) => {
        var form = document.getElementById("mig_tv_"+alias);
        var inputs = form.querySelectorAll("input, textarea, selector");
        inputs.forEach((el)=>{ 
            document.getElementById(el.id).value = "";
            console.log(el.id   );
            try {
                window.tinymce.get(el.id).setContent("");
            } catch (err) {
                console.log(err);
            }
        });
        if(form.getAttribute("data-mig-el-counter") !== undefined
        && form.getAttribute("data-mig-el-counter") !== null){
            form.removeAttribute("data-mig-el-counter");
        }
    }

    applyTvChanges = (alias) => {
        var form = document.getElementById("mig_tv_"+alias);
        var inputs = form.querySelectorAll("input, textarea, selector");
        var aliasFeald = document.getElementsByName(alias)[0];
        var been = aliasFeald.value.trim();
        var count = 0;
        var jimmi;
        try {
            jimmi = JSON.parse(been);
            console.log(jimmi);
            for(var prop in jimmi) { if(jimmi.hasOwnProperty(prop))++count; }
        } catch (err) { console.log(err); jimmi = {}; }
        var row = {};
        inputs.forEach((el)=>{ 
            row[el.id] = el.innerText;
            document.getElementById(el.id).value = "";
            try {
                window.tinymce.get(el.id).setContent("");
            } catch (err) {
                console.log(err);
            }
        });
        if(form.getAttribute("data-mig-el-counter") !== undefined
        && form.getAttribute("data-mig-el-counter") !== null){
            count = form.getAttribute("data-mig-el-counter");
            form.removeAttribute("data-mig-el-counter");
        }
        jimmi[count] = row;
        aliasFeald.value = JSON.stringify(jimmi);
        document.querySelector(".mig_close").click();
        inputs.forEach((el)=>{ el.value = ""; el.innerText = "";});
        this.formationTable();
    }

    formationTableMIGElRemove = (element, migname, el) =>{
        var aliasFeald = document.getElementsByName(migname)[0];
        var FieldJson = JSON.parse(aliasFeald.value);
        var newFieldJson = {};
        var counter = 0;
        for(var ItemId in FieldJson){
            if(el === ItemId)continue;
            newFieldJson[counter++] = FieldJson[ItemId];
        }
        //aliasFeald.innerText = JSON.stringify(newFieldJson);
        aliasFeald.value = JSON.stringify(newFieldJson);
        this.formationTable();
    }

    formationTableMIGElEdit = (element, migname,el) =>{
        let aliasFeald = document.getElementsByName(migname)[0];
        let FieldJson = JSON.parse(aliasFeald.value);
        let EditData = {};
        for(let ItemId in FieldJson){
            if(el === ItemId) EditData = FieldJson[ItemId];
        }
        document.getElementById('mig_tv_'+migname).setAttribute("data-mig-el-counter", el);
        document.getElementById('Modal'+migname+'_btn').click();
        for(let innerTvId in EditData){

            try {
                document.getElementById(innerTvId).value = EditData[innerTvId];
                window.tinymce.get(innerTvId).setContent(EditData[innerTvId]);
            } catch (err) {
                console.log(err);
            }
        };
    }
    
    formationTablePanel = (migname) =>{
            let mtp = document.getElementsByClassName("mig_table_panel");
            for(var el in mtp) {
                let MigCount = document.getElementsByClassName("mig_table_panel")[el].getAttribute("data-migcount");
                ReactDOM.render(
                    <div>
                        <button className='btn btn-primary btn-sm mr-2' name='migel-edit' onClick={(element)=>{this.formationTableMIGElEdit(element, migname, MigCount);}} data-n=''><i className='far fa-edit'></i></button>
                        <button className='btn btn-danger btn-sm' name='migel-remove' onClick={(element)=>{this.formationTableMIGElRemove(element, migname, MigCount);}} data-n=''><i className='far fa-trash-alt'></i></button>
                    </div>
                    ,
                    document.getElementsByClassName("mig_table_panel")[el]
                );
            };
    }

    formationTable = () =>{
        var mig_tv_items = document.getElementById("codex-tvlist-container").querySelectorAll(".mig_tv_item");
        var ftable = document.createElement("table");
        ftable.classList.add("table");
        ftable.classList.add("mt-2");
        ftable.classList.add("table-striped");
        ftable.classList.add("table-bordered");
        mig_tv_items.forEach( (el)=>{ 
            if(document.getElementById("codex_article_item") !== null)
            document.getElementById("codex_article_item").parentNode.removeChild(document.getElementById("codex_article_item"));
                ftable.id = el.name;
                var been = el.value.trim();
                try {
                    var outputmigel = "";
                    var jimmi = JSON.parse(been);
                    for(var prop in jimmi) {
                            outputmigel += "<tr>";
                            var jimmiprop = jimmi[prop];
                            outputmigel += "<td width='20'>"+(parseInt(prop) + 1)+"</td>";
                                for(var propvar in jimmiprop) {
                                    outputmigel += "<td>"+jimmiprop[propvar]+"</td>";
                                }
                            outputmigel += "<td width='100' data-migcount='"+prop+"' class='mig_table_panel'></td>";
                            outputmigel += "</tr>";
                    }
                    ftable.innerHTML = "<tbody>"+outputmigel+"</tbody>";
                    window.insertAfter(el, ftable);
                    this.formationTablePanel(el.name);
                } catch (err) {
                    
                }
         } );

    }
    
    resourceTvSave = async () =>{
        const tokenAPI = localStorage.getItem("tokenAPI");
        var resourceid = document.getElementById('form-resource-create').getAttribute("data-resourceid");
        var mig_tv_items = document.getElementById("codex-tvlist-container").querySelectorAll(".mig_tv_item");
        mig_tv_items.forEach(async (el)=>{ 
            let tvid = el.getAttribute("data-tvid");
            let Data = new FormData();
            Data.append('value', el.value);
            Data.append('resourceid', resourceid);
            Data.append('tvid', tvid);
            let uri = global.apiLink+`api/templates/templateariable/add?token=${tokenAPI}`;
            let api_url = await fetch(uri, {
                method: 'POST',
                body: Data,
                headers:headers
            });
            let json = await api_url.json();
            if(json.status !== 200){
                console.log(json);
                alert("Не верные данные авторизацииadfbvzsergb");
                localStorage.removeItem('tokenAPI');
            }else{
                //var templatetotvData = await json.payload;
                //console.log(templatetotvData);
            }
        });
    }

    onlineTranslit = (value) => {
        value = value.trim().replace(/\s+/g,'').toLowerCase();
        document.getElementById('form_resource_alias').value = this.cyrill_to_latin(value);
    }

    
    cyrill_to_latin = (text) => {

        var arrru = ['Я','я','Ю','ю','Ч','ч','Ш','ш','Щ','щ','Ж','ж','А','а','Б','б','В','в','Г','г','Д','д','Е','е','Ё','ё','З','з','И','и','Й','й','К','к','Л','л','М','м','Н','н', 'О','о','П','п','Р','р','С','с','Т','т','У','у','Ф','ф','Х','х','Ц','ц','Ы','ы','Ь','ь','Ъ','ъ','Э','э'];
        var arren = ['Ya','ya','Yu','yu','Ch','ch','Sh','sh','Sh','sh','Zh','zh','A','a','B','b','V','v','G','g','D','d','E','e','E','e','Z','z','I','i','J','j','K','k','L','l','M','m','N','n', 'O','o','P','p','R','r','S','s','T','t','U','u','F','f','H','h','C','c','Y','y','','','','','E', 'e'];
    
        for(var i=0; i<arrru.length; i++){
            var reg = new RegExp(arrru[i], "g");
            text = text.replace(reg, arren[i]);
        }

        return text;
    }
    
    FindCodexResource = async () => {

        var SeRCHtEXT = document.getElementById('find_codex_input').value;
        console.log(SeRCHtEXT);
        if(SeRCHtEXT.length < 3)return false;

        let API_link = global.apiLink+`api/codex/codex/list?search=${SeRCHtEXT}`;
        try {
            const api_response = await 
                fetch( API_link, {
                    method: 'GET',
                    headers:headers
                } );
            if(api_response.ok){
                let api_response_var = await api_response.json();
                console.log(api_response_var);
                this.FormationCodexList(api_response_var);
            } else {
                alert("Ошибка HTTP: " + api_response.status);
                localStorage.removeItem('tokenAPI');
                return false;
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
        
    }

    FormationCodexList = (api_response_var) => {
        var ContentContainer = document.getElementById('find_codex_list')
        var outputStr = "<table class='table table-striped table-bordered table-hover table-sm mt-3'>"
        let listCodex = api_response_var.payload.list;
        for(let i = 0; i < listCodex.length; i++){
            outputStr = outputStr+"<tr class=''><td>"+
            "<span class='btn btn-info btn-sm' style='cursor:pointer;' onclick='"+'$(".close").click();document.getElementById("form_codex_id").value='+listCodex[i].id+";'>" + (listCodex[i].id) + "</span> </td>"+
            "<td>" + (listCodex[i].title) + "</td></tr>";
        }
        ContentContainer.innerHTML = outputStr + "</table>";
    }

    resourceSave = async (e) => {
        e.preventDefault();
        var resourceid = document.getElementById('form-resource-create').getAttribute("data-resourceid");
        
        const tokenAPI = localStorage.getItem("tokenAPI");
        const Data = new FormData();
        let API_link = global.apiLink+`api/situation/situation/add?token=${tokenAPI}`;
        if(resourceid !== null){
            API_link = global.apiLink+`api/situation/situation/edit?token=${tokenAPI}`;
            Data.append('resourceid', resourceid);
        } 
        if(
            document.getElementById('form_resource_title').value !== document.getElementById('form_resource_title').getAttribute("data-value")
        ) Data.append('title', document.getElementById('form_resource_title').value);
        
        if(
            document.getElementById('form_resource_name').value !== document.getElementById('form_resource_name').getAttribute("data-value")
        ) Data.append('name', document.getElementById('form_resource_name').value);
        if(
            document.getElementById('form_resource_alias').value !== document.getElementById('form_resource_alias').getAttribute("data-value")
        ) Data.append('alias', document.getElementById('form_resource_alias').value);

        Data.append('parent_id', document.getElementById('form_resource_parentid').value);
        Data.append('active', document.getElementById('form_resource_active').value);
        //Data.append('description', document.getElementById('form_resource_description').value);
        //Data.append('idtemplate', document.getElementById('form_resource_id_template').value);
        let fr_content = "";
        try {
            fr_content = window.tinymce.get('form_resource_content').getContent();
        } catch (err) {
            console.log(err);
        }
        //if(fr_content == "")fr_content = document.getElementById('form_resource_content').value;

        Data.append('content', fr_content);
        Data.append('codex_id', document.getElementById('form_codex_id').value);
        Data.append('tags', document.getElementById('form_tags').value);
        
        try {
            const api_response = await 
            fetch(API_link, {
                method: 'POST',
                body: Data,
                headers:headers
            });
            if(api_response.ok){
                let api_response_var = await api_response.json();
                console.log(API_link);
                console.log(api_response_var);
                if(api_response_var.payload.id > 0) alert("Готово");
                //this.resourceTvSave(api_response_var.payload.id);
                //document.querySelector("[name=refreshtree]").click();
            } else {
                alert("Ошибка HTTP: " + api_response.status);
                localStorage.removeItem('tokenAPI');
                return false;
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }
    
    render(){
        //this.resourceGetTemplates();

        return (
            <div id="codex-work-area" className="col-md-8 float-right">
                <div id="resource-create">
                    <form id="form-resource-create">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button className="btn btn-primary btn-sm" onClick={this.resourceSave}>Сохранить</button>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput">Заголовок</label>
                                    <input type="text" className="form-control" name="form_resource_title" id="form_resource_title" onChange = {() => this.onlineTranslit(document.getElementById("form_resource_title").value)} placeholder="Название ресурса" />
                                </div>
                                {/*}<div className="form-group">
                                    <label htmlFor="formGroupExampleInput3">Заголовок</label>
                                    <input type="text" className="form-control" name="form_resource_name" id="form_resource_name" placeholder="Заголовок (ситуация в двух словах)" />
                                </div>{/**/}
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formGroupExampleInput">Статус</label>
                                            <select className="custom-select" name="form_resource_active" id="form_resource_active" required>
                                            <option value="1" >Активен</option>
                                            <option value="0">Не активен</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="form_codex_id">ID ресурса Кодекс</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                <input type="text" name="form_codex_id" id="form_codex_id" className="form-control" placeholder="ID раздела Кодекс" />
                                                </div>
                                                <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" style={{"margin-top":"-1px"}} data-toggle="modal" data-target="#find_codex_modal" type="button">Указать</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formGroupExampleInput2">Псевдоним</label>
                                            <input type="text" className="form-control" name="form_resource_alias" id="form_resource_alias" placeholder="nazvanieresursa" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="formGroupExampleInput2">Раздел</label>
                                            <select className="custom-select" name="form_resource_parentid" id="form_resource_parentid" required>
                                            <option value="1">УК РФ</option>
                                            <option value="2">КоАП РФ</option>
                                            <option value="3">НК РФ</option>
                                            </select>
											
                                        <div className="form-group d-none">
                                            <label htmlFor="formGroupExampleInput">Родитель</label>
                                            <input type="text" className="form-control" name="form_resource_parentid__" id="form_resource_parentid" placeholder="id родительского ресурса" />
                                        </div>
                                        </div>
                                    </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="form_tags">Теги</label>
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <input type="text" name="form_tags" id="form_tags" className="form-control" placeholder="Теги не указаны" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="validationTextarea">Описание</label>
                            <textarea className="form-control"  name="form_resource_name" id="form_resource_name"></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="validationTextarea">Содержимое</label>
                            <textarea className="form-control richtext"  name="form_resource_content" id="form_resource_content"></textarea>
                        </div>
                    </form>
                </div>
                <div id="resource-tv-wrapper">
                    <div className="row">
                        <div className="d-none"><button name="refreshtvlist" onClick={()=>this.resourceGetTemplateTVs()}></button></div>
                        <div id="codex-tvlist-container" className="col-md-12"></div>
                    </div>
                </div>
                <div className="modal fade" id="find_codex_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Выбрать ресурс кодекса</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form className="form-inline">
                            <div className="form-group">
                                <input type="text" id="find_codex_input" onChange={()=>this.FindCodexResource()}  className="form-control mx-sm-6" placeholder="Найти ресурс кодекса" />
                            </div>
                        </form>
                        <div id="find_codex_list">

                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Отмена</button>
                        <button type="button" className="btn btn-primary">Применить</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        );


    }
}

class Situations extends React.Component {
    render(){
        return (
            <div>
                <Toppanel title="Управление ситуациями" />
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <TreeSituations />
                            <WorkAreaSituations />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Situations;