import React from 'react';
import DocsResourceviewerForm from "./docsresourceviewerform";
import ReactDOM from 'react-dom';
import TreeDocs from "./treedocs";

class Childlist extends React.Component{
    constructor(props) {
        super(props);
        this.state = {childs : props.childs,
                      handlechangeForm :props.handlechangeForm
        };


    }

    editAction = async (id) => {
        ReactDOM.unmountComponentAtNode(document.getElementById('docs-work-area'))
        ReactDOM.render(<DocsResourceviewerForm id={id}  updateTree={this.state.handlechangeForm}  />,document.getElementById('docs-work-area'));
    }
    static getDerivedStateFromProps(props,state){
        return {
            childs:props.childs
        }
    }

    delete = async(id,i) => {
        let url = global.apiLink + '/doc/document/delete?id=' + id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let api_url = await fetch(url,{
            method:'DELETE',
            headers:headers,

        });
        let result = await api_url;
        console.log(result.status);
        if(result.status === 204){
            let childs = this.state.childs;
            this.setState({childs:childs.splice(i,1)});
        }
    }
    render() {
        const childs = this.state.childs;
        return (
            <ul key={this.props.id}>
                {childs.map((element,i)=>
                        <li key={element.id}>
                            <i className="far fa-sticky-note mr-2" aria-hidden="true"/>
                            <a className="resource_title" onClick={()=>this.editAction(element.id)}>{element.name}</a>
                            <i className="fas fa-minus-circle ml-2 resource_remove" title="Удалить"
                               aria-hidden="true" onClick={()=>this.delete(element.id,i)}/>
                        </li>
                )}
            </ul>
        );
    }
}
export default Childlist;