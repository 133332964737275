import React from 'react';
//import ReactDOM from 'react-dom';
//import Codex from '../interface/codex/codex';
//import Questionanswer from '../interface/qa/questionanswer';
//import Situation from '../interface/situation/situation';
//import ParseDocx from '../interface/parsedocx/parsedocx';
//import EditorJurists from '../interface/editorjurists/editorjurists';
import ElementSidebar from "./element";

import "../../global.js";

class Rightsidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            menus:[
                {
                    name:"Кодекс",
                    url:"/interface/codex/",
                    iconClass:"sidebar-menu-icon fas fa-sitemap"
                },
                {
                    name:"Вопрос-ответ",
                    url:"/interface/qa/",
                    iconClass:"sidebar-menu-icon far fa-question-circle"
                },
                {
                    name:"Ситуации",
                    url:"/interface/situation/",
                    iconClass:"sidebar-menu-icon fas fa-bolt"
                },
                {
                    name:"Документы",
                    url:"/interface/docs/",
                    iconClass:"sidebar-menu-icon fas fa-drafting-compass"
                },
                {
                    name:"Справочная информация",
                    url:"/interface/inf/regions/",
                    iconClass:"sidebar-menu-icon fas fa-book ",
                    sublist:[
                        {
                            name:"Типы справочной информации",
                            url:"/interface/inf/type-subjects",
                            iconClass:"sidebar-menu-icon fas fa-book ",
                        }
                    ]
                },
                {
                    name:"Новости",
                    url:"/news/",
                    iconClass: "sidebar-menu-icon fas fa-newspaper"
                },
                /*{
                    name:"Парсить Docx",
                    url:"/interface/parsedocx/",
                    iconClass:"sidebar-menu-icon fas fa-drafting-compass"
                },*/
                {
                    name:"Пользователи",
                    url:"/interface/users/",
                    iconClass: "fa-user-0"
                },
				{
                    name:"Редактор Адвокатов",
                    url:global.apiLink+'editor/jurist/login/?do=auth&login=bhkuSDHRkpw34uJy8909H58tup54JTu9y85D6&pass=JSK43U5YH75Gdrth6erhSGDF65rhs2f65RFdgGY',
                    iconClass:"sidebar-menu-icon fas fa-drafting-compass",
                    target:"_blank"
                },
                {
                    name:"Редарктор полномочий",
                    url:'/interface/permissions/'

                },
                {
                    name:'Роли',
                    url:'/interface/roles/'

                },
                {
                    name:'Назначение консультанта',
                    url:'/interface/setconsulter/'
                },
                {
                    name:'Дежурный телефон',
                    url:'/interface/call/'
                },
                {
                    name:'Выйти',
                    url:'/logout/'
                }


            ]

        };
    }

    render(){
        let menus = this.state.menus;
        const rightSidebar = menus.map((element,index)=>
            <ElementSidebar key={index} name={element.name} url={element.url} iconClass={element.iconClass} target={element.target} subMenu={element.sublist} />
        );
        return (
            <div id="rightsidebar">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 logo-wrapper">
                                <img src="/assets/images/logo.png" className="logo" alt="" width="30"/>
                            </div>
                            <div className="col-md-9">
                                <h4 className="logo-title mb-0">Femida</h4>
                                <p className="logo-subtitle small">Адвокат online</p>
                            </div>
                            <div className="col-md-12"><hr className="header" /></div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div id="right-menu" className="sidebar-menu">
                            <ul className="right-menu-tree">
                                {rightSidebar}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default Rightsidebar;