import React from 'react';
import InputMask from "react-input-mask";
import $ from 'jquery'
import suggession from 'suggestions-jquery';
import './user-css.css'
import RadioButton from "../../elements/RadioButton";
class EditUser extends React.Component{
    constructor(props) {
        super(props);
        let id=0;
        if(props.id){
            id = props.id;
        }
       // let today = new Date();

        this.state = {
            id:id,
            name:'',
            surname:'',
            middleName:'',
            email:"",
            login:'',
            type_acc:0,
            phones: [{id: 0, data: ''}],
            socialLinks: [{id:0,link:'',social_id:0}],
            socialList:[{id:0,name:''}],
           // roles:[],
            allroles:[],
            readOnly: true
        };

    }
    badRequest(payload){
        console.log(payload);
        let result='';
        for(let key in payload){
            result +=payload[key].join(' ')+"\n";
        }
        alert(result)
    }
    save  = async (e) => {
        e.preventDefault();
        let formData = new FormData(e.target);

        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink+'/api/user/edit';
        fetch(url,{
            method:'POST',
            body:formData,
            headers:headers
        })
            .then(responce => responce.json())
            .then((response)=>{
                if(response.payload){
                    if(response.status === 400){
                        this.badRequest(response.payload)
                    }
                    if(response.payload.id){
                        window.location.href = '/interface/users/'+response.payload.id;
                    }

                }
            })
    }

    handleChangePhones(i, event) {
        let phones = [...this.state.phones];
        phones[i].data = event.target.value;
        this.setState({phones: phones});
    }
    addPhoneInput() {
        this.setState(prevState => ({phones: [...prevState.phones, {id: 0, name: ''}]}));
    }
    addSocialInput() {
      //  this.setState(prevState = >({socialLinks:[...prevState.socialLinks,{id:0,socialLinks:''}]}))
        this.setState(prevState =>({socialLinks:[...prevState.socialLinks,{id:0,socialLinks:''}]}));
    }
    handleChangeLink(i,event){
        let socialLinks = [...this.state.socialLinks];
        socialLinks[i].link= event.target.value;
        this.setState({socialLinks:socialLinks});
    }
    handleChangeSocialId(i,event){
        let socialLinks = [...this.state.socialLinks];
        socialLinks[i].social_id = event.target.value;
        this.setState({socialLinks:socialLinks});
    }
    deleteContact(i) {
        let phones = this.state.phones;
        delete phones[i];
        phones = phones.slice(0, i).concat(phones.slice(i + 1))
        this.setState({phones: phones});
    }
    componentDidMount() {
        if(this.state.id>0){
            let headers = new Headers();
            headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
            let url = global.apiLink+'/api/user/get?id='+this.state.id;
            fetch(url,{
                headers:headers
            })
                .then(res=>res.json())
                .then((response) =>{
                    if(response.payload){
                        let phones = [{id: 0, data: ''}];
                        if(response.payload.contacts){
                            if(response.payload.contacts.length>0){
                                phones = response.payload.contacts;
                            }
                        }
                        let socialLinks = [{id:0,link:'',social_id:0}];
                        if(response.payload.socialLinks){
                            if(response.payload.socialLinks.length>0){
                                socialLinks = response.payload.socialLinks;
                            }
                        }
                        let roles = response.payload.roles;
                        if(roles.length>0){
                            roles = roles.map((role)=>{
                                return role.id;
                            });
                        }



                        this.setState({
                            id: response.payload.id,
                            name: response.payload.name,
                            surname: response.payload.surname,
                            middleName: response.payload.middleName,
                            login:response.payload.login,
                            email:response.payload.email,
                            typeAcc:response.payload.type_acc,
                            phones: phones,
                            gender:response.payload.gender,
                            address: response.payload.address,
                            description:response.payload.description,
                            socialLinks: socialLinks,
                            socialList: response.payload.socialList,
                            roles:roles,
                        });
                    }

                });
            let urlRoles = global.apiLink+'/api/role/list';
            fetch(urlRoles,{
                headers:headers
            })
                .then(res=>res.json())
                .then((res)=>{
                    let roles = res.payload;
                    roles = roles.map((role)=>{
                        return {value:role.id,label:role.title};
                    });
                    this.setState({allroles:roles});
                });
            let suggestionGLOBAL = {};
            $('#form_resource_address').suggestions({
                token: 'ad483a487debfff5856f869f7d375a34d796542f',
                type: 'ADDRESS',
                count: 15,
            });
        }
    }

    render() {
        let self = this;
        let roles = null;
        if(this.state.allroles.length >0 && this.state.roles){
            roles =  <RadioButton name="roles[]" allOptions={this.state.allroles} selectedElements={this.state.roles}/>;
        }

        let {phones} = this.state;
        let {socialLinks} = this.state;
        let {socialList} = this.state;
        socialList.unshift({id:0,name:'не выбранно'});
        let socialSelectOptions = socialList.map((element,i)=>
        <option key={element.id} value={element.id}>
            {element.name}
        </option>);

        return (
                <div>
                    <form onSubmit={(e) =>this.save(e)}>
                        <input type="hidden" value={this.state.id} name ="id" />
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button type="submit" className="btn btn-primary btn-sm">Сохранить
                                </button>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Логин</label>
                                    <input type="text" className="form-control" name="login"
                                           value={this.state.login} onChange={e =>this.setState({login:e.target.value})}
                                           placeholder="Логин пользователя"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput">Имя</label>
                                    <input type="text" className="form-control" name="name" id="form_resource_title"
                                           value={this.state.name} onChange={e => this.setState({name: e.target.value})}
                                           placeholder="Имя пользователя"/>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Фамилия</label>
                                    <input type="text" className="form-control" name="surname"
                                    value={this.state.surname} onChange={e =>this.setState({surname:e.target.value})}
                                           placeholder="Фамилия пользователя"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Отчество</label>
                                    <input type="text" className="form-control" name="middle_name"
                                           value={this.state.middleName} onChange={e =>this.setState({middleName:e.target.value})}
                                           placeholder="Отчество пользователя"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Почта</label>
                                    <input type="text" className="form-control" name="email"
                                           value={this.state.email} onChange={e =>this.setState({email:e.target.value})}
                                           placeholder="Отчество пользователя"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Пароль</label>
                                    <input type="password" className="form-control" name="password"
autocomplete="off"
                                           placeholder="Введите пароль"
                                           readOnly={self.state.readOnly}
                                           onFocus={e=>self.setState({readOnly:false})}
                                    />
                                </div>
                            </div>

                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Повторите пароль</label>
                                    <input type="password" className="form-control" name="password-repeat"
autocomplete="off"
                                           placeholder="Отчество пользователя"
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>О себе</label>
                                    <textarea name="description" className="form-control" value={this.state.description}
                                     onChange={e => this.setState({description:e.target.value})}/>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>Гендер</label>
                                    <select name="gender" value={this.state.gender} className="form-control" onChange={e =>this.setState({gender:e.target.value})}>
                                        <option value="">Не выбрано</option>
                                        <option value="M">Мужчина</option>
                                        <option value="F">Женщина</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label>День рождения</label>
                                    <input type="date" className="form-control" max=""/>
                                </div>

                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Роли</label>
                                    {roles}
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>Адрес</label>
                                    <input type="text" className="form-control" name="address" id="form_resource_address"
                                           placeholder="Введите адресс" value={this.state.address} onChange={e =>this.setState({address:e.target.value})} />
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="row">
                                <div className="form-group col-md-10">
                                    <label>Соц сети</label>
                                    <fieldset>
                                        {socialLinks.map((element,i)=>
                                            <div className="phone-div" key={element.id}>
                                                <input type="hidden" name={"links["+i+"][id]"} value={element.id}/>
                                                <input name={"links["+i+"][link]"} value={element.link}
                                                       placeholder="Ссылка" className="form-control"
                                                        onChange={this.handleChangeLink.bind(this,i)}/>
                                                <select  value={element.social_id} name={"links["+i+"][social_id]"}
                                                        onChange={this.handleChangeSocialId.bind(this,i)} className="form-control">
                                                    {socialSelectOptions}
                                                </select>
                                            </div>
                                            )}

                                    </fieldset>
                                </div>
                                <div className="col-md-1 btn-pluss">
                                    <button onClick={()=>this.addSocialInput()} type="button" className="btn btn-info">
                                       +
                                    </button>
                                </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <label>Контактный телефон(-ы)</label>
                                        <br/>
                                        <fieldset>
                                            {phones.map((element, i) =>

                                                <div className="phone-div" key={element.id}>

                                                    <InputMask mask="+7 (999) 999-99-99" data-id={element.id} type="tel"
                                                               maskChar={null} name={"phones["+i+"][data]"} value={element.data || ''}
                                                               onChange={this.handleChangePhones.bind(this, i)}
                                                               className="form-control"/>
                                                    <input type="hidden" name={"phones["+i+"][id]"}/>
                                                    <i className="delete-icon"
                                                       onClick={() => this.deleteContact(i)}>x</i>
                                                </div>
                                            )}
                                        </fieldset>
                                    </div>
                                    <div className="btn-pluss">
                                        <button type="button" onClick={() => this.addPhoneInput()} className="btn btn-info">
                                            добавить еще номер
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
        );
    }
}
export default EditUser;