import React from 'react';
import {Link} from "react-router-dom";
import FormSubjects from "./formsubjects";

class Subjects extends React.Component{
    constructor(props) {
        super(props);
        console.log(this.props);
        this.state={
            list :props.list,
            region_id:props.region_id,
          //  current_id:props.match.params.id
        };

    }
    delete(id){
        let url = global.apiLink+'/subjects/subject/remove';
        let formData = new FormData();
       // console.log(this.state.current_id);
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        formData.append('id',id);
        fetch(url,{method: 'POST', body: formData,headers:headers})
            .then(res=>res.json())
            .then((result)=>{
                 if(this.state.list){
                     this.setState({list:this.state.list.filter(element => element.id != id)});
                 }

            })
    }
    render() {
        let {list,region_id} = this.state;
        return(
            <ul>
                {list.map((element)=>
                    <li key={element.id}>
                        <i className="far fa-sticky-note mr-2" aria-hidden="true"/>
                        <Link className="resource_title" to={"/interface/inf/subject/"+element.id+"?region_id="+region_id}  >{element.name}</Link>
                        <i className="fas fa-minus-circle ml-2 resource_remove" onClick={()=>this.delete(element.id)} title="Удалить"
                           aria-hidden="true" />
                    </li>
                )}
            </ul>
        );

    }
}
export default Subjects;