import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Dashboard from './dashboard';
import Codex from './interface/codex/codex';
import Qa from './interface/qa/questionanswer';
import Situation from './interface/situation/situation';
import ParseDocx from './interface/parsedocx/parsedocx';
//import Treedocs from "./interface/docs/treedocs";
import Docs from "./interface/docs/docs";
import Information from "./interface/information/information";
import NewsForm from "./interface/news/newsform";
import NewsPage from "./interface/news/newspage";
import TypeSubject from "./interface/information/type-subject/typesubject";
import EditorJurists from './interface/editorjurists/editorjurists';
import UsersComponent from "./interface/users/UsersComponent";
import Roles from "./interface/roles/Roles";
import Permissions from "./interface/permissions/Permissions";
import CallResource from "./interface/call/CallResource";
import SetConsulter from "./interface/setconsulter/setconsulter";

class BodyT extends React.Component {

    render(){
        return (
            <BrowserRouter>
                <div id="wrapperbody">
                    <div className="container-fluid page_container">
                        <div id="innerbody">
                            <Switch>
                                <Route exact path='/' component={Dashboard}/>
                                <Route exact path='/interface/codex/' component={Codex}/>
                                <Route exact path='/interface/qa/' component={Qa}/>
                                <Route exact path='/interface/users/:id?' component={UsersComponent}/>
                                <Route exact path='/interface/situation/' component={Situation}/>
                                <Route exact path="/interface/setconsulter/" component={SetConsulter}/>
                                <Route exact path='/interface/parsedocx/' component={ParseDocx}/>
                                <Route exact path='/interface/news/edit/0' component={NewsForm}/>
                                <Route exact path='/interface/call' component={CallResource} />
                                <Route exact path="/interface/roles/:id?" component={Roles}/>
                                <Route exact path="/interface/permissions/:id?" component={Permissions} />
                                <Route exact path='/interface/docs/' component={Docs}/>
                                <Route  path='/news/:id?' component={NewsPage}/>
                                <Route exact  path="/interface/inf/type-subjects/:id(\d+)?" component={TypeSubject} />
                                <Route  path='/interface/inf/' component={Information} />
                                <Route  path='/interface/editorjurists/' component={EditorJurists} />
                                <Route exact path="/logout/" render={()=>{localStorage.removeItem('tokenAPI');window.location.href = "/";}} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

export default BodyT;