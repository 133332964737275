import React from 'react'
import ReactDOM from "react-dom";
import NewsForm from "../../news/newsform";
import {Link} from "react-router-dom";

class TypeSubjectList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: null
        }
    }

    getChildButton = (parentId, classname_str = "", tagName = "i") => {
        return (
            <>
                {React.createElement(tagName, {
                    className: classname_str + 'fas fa-plus-circle ml-2 resource_add_child',
                    dataPid: parentId,
                    title: 'Добавить тип',
                    onClick: () => this.addType()
                },)}
            </>
        );
    }
    addType() {
       // ReactDOM.unmountComponentAtNode(document.getElementById('form-news'));
        window.location = "/interface/inf/type-subjects/0";
        //ReactDOM.render(<NewsForm id="0"/>, document.getElementById('form-news'));
    }

    componentDidMount() {
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink + 'subjects/type/list'
        fetch(url,{
            headers:headers
        })
            .then(res => res.json())
            .then(
                (response) => {
                    if (response.payload) {
                        this.setState({
                            list: response.payload
                        });
                    }

                })
    }
    delete = async (id) => {
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink +"subjects/type/delete?id="+id;
        fetch(url,{
            headers:headers
        })
            .then(res => res.json())
            .then((result)=>{
                window.location = '/interface/inf/type-subjects/'
            })
    }
    render() {
        let treeElements = null;
        let {list} = this.state;
        if (this.state.list) {
            treeElements = list.map((element) =>
                <li key={element.id}>

                        <i className="far fa-folder isfolder-btn mr-2" aria-hidden="true"/>
                        <i className="fas fa-book mr-2" aria-hidden="true"/>
                        <Link className="resource_title" data-toggle="tooltip" to={"/interface/inf/type-subjects/"+element.id} data-placement="top">
                            <span>{element.name}</span>
                        </Link>
                        <i className="fas fa-minus-circle ml-2 resource_remove" onClick={() =>this.delete(element.id)} />

                </li>
            );
        }
        return (

                <div className="col-md-4">
                    <div className="row">
                        <ul className="mb-1 pb-0">

                            <li>
                                <span className="resource_title " data-toggle="tooltip" data-placement="top"
                                      title="Рут"></span> <a className="resource_title" data-toggle="tooltip"
                                                             data-placement="top" title={"Документы"}>Тип события</a>
                                {this.getChildButton(0)}
                            </li>

                        </ul>
                        <div id="codex-tree-container" className="col-md-11 ml-3">
                            <ul>
                                {treeElements}
                            </ul>
                        </div>
                    </div>
                </div>

        )
    }
}

export default TypeSubjectList;