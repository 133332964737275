import React from 'react';
import Toppanel from "../top_panel";
import List from "./List";
import ReactDOM from "react-dom";
import Edit from './Edit';

class Roles extends React.Component {
    constructor(props) {
        super(props);
        let id = 0;
        if (props.match.params.id) {
            id = props.match.params.id;
        }
        this.state = {
            roleId: id
        };
    }

    componentDidMount() {
        if (this.state.roleId) {
            ReactDOM.render(<Edit id={this.state.roleId}/>, document.getElementById('work-area'));
        }
    }

    render() {
        return (
            <div>
                <Toppanel title="Управление Пользователями"/>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <List idCurrent={this.state.roleId}/>
                            <div id="work-area" className="col-md-8 float-right">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Roles;