import React from 'react';
import Toppanel from "../top_panel";
import Treedocs from "./treedocs";

class Docs extends React.Component{
    constructor(props) {
        super(props);

    }
    render() {
        return(
            <div>
                <Toppanel title="Управление Документами" />
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <Treedocs/>
                            <div id="docs-work-area" className="col-md-8 float-right">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Docs;