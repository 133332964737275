import React from 'react';
import Toppanel from '../top_panel';

import "../../../global.js";



class EJ extends React.Component {

    render (){
	var iframeStyle = {
		width:"100%",
        border:"0",
        height:"1000px",
	}; 

        return (
            <div>
                <Toppanel title="Редактировать Адвокатов" />
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" >
                                <iframe
                                    src={global.apiLink+"/editor/jurist"}
                                    style={iframeStyle} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EJ;	