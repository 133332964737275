import React from 'react'
import NewsList from "./newslist";
import NewsForm from "./newsform";
import ReactDOM from 'react-dom';

class NewsPage extends React.Component {

    constructor(props) {
        super(props);
        let id =0;
        if (props.match.params.id) {
            id = props.match.params.id;
            //ReactDOM.render(<NewsForm id={id}/>,document.getElementById('form-news'));

        }

        this.state = {
            news_id:id
        };

    }
    componentDidMount() {
        if(this.state.news_id){
            ReactDOM.render(<NewsForm id={this.state.news_id}/>,document.getElementById('form-news'));
        }

    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <NewsList/>
                        <div className="col-md-7" id="form-news">

                        </div>

                    </div>
                </div>
            </div>
        )
            ;


    }
}

export default NewsPage;