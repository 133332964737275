import React from 'react';
import RadioButton from "../../elements/RadioButton";

class Edit extends React.Component{
    constructor(props) {
        super(props);
        let id=0;
        if(props.id){
            id = props.id;
        }
        // let today = new Date();

        this.state = {
            id:id,
            title:'',
            description:'',
            //optionsPermission:[],
            allOptions:[]
        };

    }
    save(e){
        e.preventDefault();


        let formData = new FormData(e.target);

        let url = global.apiLink+"/api/role/edit";
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            body:formData,
            headers:headers,
            method:'POST'
        })
            .then(response =>response.json())
            .then((response)=>{
               if(response.status ===200 && response.payload){
                   window.location.href = '/interface/roles/'+response.payload.id;
               }
            })
            .catch((reason)=>{
                alert('whoops something went wrong');
            });
    }
    changePermissions(e){
        console.log(e);
        console.log('change perm');
    }
    componentDidMount() {
        if(this.state.id !=0){
            let headers = new Headers();
            console.log('testtttttt');
            headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
            let url  = global.apiLink +"/api/role/get?id="+this.state.id;
            fetch(url,{
                headers:headers
            })
                .then(response => response.json())
                .then((response)=>{
                    if(response.status == 200 && response.payload){
                        let permissions = response.payload.permissions

                        let optionsPermission = permissions.map(function (permission){
                            return permission.id;
                        });
                        this.setState({
                            title:response.payload.title,
                            description: response.payload.description,
                            optionsPermission:optionsPermission
                        });


                }
                    else{

                      //  window.location.href = '/interface/roles/';
                    }

                })
                .catch((response)=>{
                    alert('Сущность не найдена');

                })
            let urlPermissions= global.apiLink +'/api/permission/list';
            fetch(urlPermissions,{
                headers:headers
            })
                .then(response => response.json())
                .then((response)=>{

                    if(response.status ===200&& response.payload){
                        let permissions = response.payload;
                        let options = permissions.map((permission)=>{
                            return {value:permission.id,label:permission.title+'('+permission.module+')'}
                        });
                        //console.log(options);
                        this.setState({allOptions:options});
                    }
                })
        }

    }

    render() {
        let permissions = null;
        if(this.state.allOptions.length >0 && this.state.optionsPermission){
             permissions =  <RadioButton name="permissions[]" allOptions={this.state.allOptions} selectedElements={this.state.optionsPermission}/>;
        }


        return(
            <div>
                <form onSubmit={(e) =>this.save(e)}>
                    <input type="hidden" name="id" value={this.state.id}/>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm">Сохранить
                            </button>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Тайтл</label>
                                <input type="text" className="form-control" name="title"
                                       value={this.state.title} onChange={e =>this.setState({title:e.target.value})}
                                       placeholder="Тайтл"/>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Описание Роли</label>
                                <input type="text" className="form-control" name="description"
                                value={this.state.description} onChange={e=> this.setState({description:e.target.value})}
                                placeholder="Описание"/>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <div>
                                    {permissions}
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export default Edit;