import React from 'react';

class Edit extends React.Component{

    constructor(props) {
        super(props);
        let  id = props.id;
        console.log(id);
        this.state = {
            id:id,
            title:'',
            description:'',
            classes:[],
            methods:[]

        };
    }
    componentDidMount() {
        let id = this.state.id;
        let urlPermissions = global.apiLink +'/api/permission/get?id='+id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(urlPermissions,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                if(response.status === 200 && response.payload){
                    if(response.payload.method){
                        this.initMethodSelect(response.payload.controller);
                    }
                    this.setState({
                        id:response.payload.id,
                        title:response.payload.title,
                        description:response.payload.description,
                        controller:response.payload.controller,
                        method:response.payload.method
                    });
                }
            });
        let urlClass = global.apiLink +'/api/permission/get-classes';

        fetch(urlClass,{
            headers:headers
        })
            .then(response => response.json())
            .then((response)=>{
                if(response.status === 200 && response.payload){
                    this.setState({classes:response.payload});
                }
            })

    }
    initMethodSelect(controller){
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink +'/api/permission/method-class?class='+controller;
        fetch(url,{
            headers:headers
        })
            .then(response =>response.json())
            .then((response)=>{
                if(response.status === 200 && response.payload){
                    this.setState({methods:response.payload});
                }
            })
    }
    save(e){
        e.preventDefault();
        let formData = new FormData(e.target);
        let url = global.apiLink+'/api/permission/edit';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers,
            method:'POST',
            body:formData
        })
            .then(response=>response.json())
            .then((response)=>{
                console.log('Разрешение обновленно');
            })
    }
    changeClass(e){
        let className = e.target.value;
        console.log(className);
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink +'/api/permission/method-class?class='+className;
        this.setState({controller:className});
        fetch(url,{
            headers:headers
        })
            .then(response =>response.json())
            .then((response)=>{
                if(response.status === 200 && response.payload){
                    this.setState({methods:response.payload});
                }
            })
    }

    render() {
        return(
            <div>
                <form onSubmit={(e) =>this.save(e)}>
                    <input type="hidden" name='id' value={this.state.id}/>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm">Сохранить
                            </button>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Тайтл</label>
                                <input type="text" className="form-control" name="title"
                                       value={this.state.title} onChange={e =>this.setState({title:e.target.value})}
                                       placeholder="Тайтл"/>
                            </div>

                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Описание Роли</label>
                                <input type="text" className="form-control" name="description"
                                       value={this.state.description} onChange={e=> this.setState({description:e.target.value})}
                                       placeholder="Описание"/>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Модуль</label>
                                <select className="form-control" name="controller"
                                       value={this.state.controller} onChange={this.changeClass.bind(this)}
                                       placeholder="Модуль">
                                    {this.state.classes.map((element,i)=>
                                        <option key={i} value={element.class}>{element.title}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Действие</label>
                                <select type="text" className="form-control" name="method"
                                       value={this.state.method} onChange={e=> this.setState({method:e.target.value})}
                                       placeholder="Действие">
                                    {this.state.methods.map((method,index)=>
                                        <option key={index} value={method.action}>{method.title}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
export default Edit;