import React from 'react';

class HeaderT extends React.Component {

    render(){
        
        return (
            <div id="header" >
                <div className="row">
                    
                </div>
            </div>
        );
    }
}
 
export default HeaderT;