import React from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css';

class NewsForm extends React.Component {
    constructor(props) {
        super(props);
        let id = 0;
        if (props.id) {
            id = props.id;
        }

        this.state = {
            id: id,
            title: '',
            announcement: '',
            description: '',
            imagePreviewUrl: '',
            file: '',
            alias: ''
        }
    }

    save = async (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('title', this.state.title);
        formData.append('anonce_text', this.state.announcement);
        formData.append('full_text', this.state.description);
        formData.append('preview', this.state.file);
        formData.append('alias',this.state.alias);
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink + "news/edit";
        const api_url = await fetch(url,
            {
                method: 'POST', body: formData,
                headers:headers
            });
        const json = await api_url.json();
        if (json.status !== 200) {
            alert('some thing went wrong(((');
        }
        if (json.payload) {
            let id = json.payload.id;
            window.location = '/news/' + id;
        }

    }

    onFileInputChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }

    componentDidMount() {
        let id = this.state.id;
        let url = global.apiLink + 'news/get?id=' + id;
        fetch(url).then(res => res.json()).then(
            (result) => {
                if (result.status == 200 && result.payload) {
                    console.log(result);
                    let imgUrl = '';
                    if (result.payload.file) {
                        imgUrl = global.apiLink + 'uploads/' + result.payload.file.path + '/' + result.payload.file.name;
                    }

                    this.setState({
                        id: id,
                        title: result.payload.title,
                        announcement: result.payload.anonce_text,
                        description: result.payload.full_text,
                        imagePreviewUrl: imgUrl,
                        alias: result.payload.alias
                    });
                }
            }
        );
    }

    render() {
        let {imagePreviewUrl} = this.state;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = (<img src={imagePreviewUrl}/>);
        } else {
            $imagePreview = (<div className="previewText">Please select an Image for Preview</div>);
        }
        return (
            <div className="col-md-12">
                <div className="col-md-12">
                    <form onSubmit={(e) => this.save(e)}>
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm">Сохранить</button>
                        </div>
                        <input type="hidden" name="id" value={this.state.id}/>

                        <div className="col-md-9">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Заголовок</label>
                                <input type="text" className="form-control" value={this.state.title} name="title"
                                       onChange={e => this.setState({title: e.target.value})}/>

                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label>Алиас</label>
                                <input type="text" className="form-control" value={this.state.alias} name="alias"
                                       onChange={e => this.setState({alias: e.target.value})}/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Анонс</label>
                                <textarea className="form-control" name="anonce-text"
                                          onChange={e => this.setState({announcement: e.target.value})}
                                          value={this.state.announcement}/>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Подробное описание</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.description}
                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        console.log(editor);
                                        this.setState({description: data});
                                        console.log(data);

                                    }}
                                    onBlur={(event, editor) => {

                                    }}
                                    onFocus={(event, editor) => {

                                    }}
                                    config={{
                                        ckfinder: {
                                            uploadUrl: global.apiLink + 'news/upload',
                                            options: {
                                                resourceType: 'Images'
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Картинка превью</label>
                                <input className="fileInput" type="file" onChange={(e) => this.onFileInputChange(e)}/>
                            </div>
                        </div>
                        <div className="imgPreview">
                            {$imagePreview}
                        </div>
                    </form>
                </div>

            </div>

        );

    }
}

export default NewsForm;