import React from 'react';
import './RadioButtonCss.css'
class RadioButton extends React.Component{
    constructor(props) {
        super(props);
        let allOptions = props.allOptions.map((option)=>{
            option.checked = props.selectedElements.includes(option.value);
            return option;
        });

        this.state={
            allElement : allOptions
        };

    }
    change(key,e){
        let options = this.state.allElement;
        console.log(options);
        options[key].checked = !options[key].checked;
        this.setState({allElement:options});
    }
    render() {
        let allElement = this.state.allElement;
        let inputRadio = allElement.map((element,key)=>
            <div key={key}>
                <input type="checkbox" name={this.props.name} value={element.value} onChange={this.change.bind(this,key)}  checked={element.checked} />
                <label>{element.label}</label>
                <br/>
            </div>
        );
        return (
            <fieldset className='checkbox-fixed'>
                {inputRadio}
            </fieldset>
        )
    }

}
export default RadioButton;