import React from 'react';
import Toppanel from "../top_panel";
import Peer from "peerjs";
import './call.css';

var tokenAPI = localStorage.getItem('tokenAPI');

var headers = new Headers();
headers.append('Authorization', 'Bearer ' + tokenAPI);

class CallResource extends React.Component{
    constructor(props) {
        super(props);
        const combinedStream = new MediaStream();
        this.state ={
            call:null,
            stateCall:0,
            VideoAvalable: false,
            AudioAvalable: false,
            VideoUpload: false,
            VideoPlayerStatus: "play",
            CallList: [],
        };
        let callOptions={
            host:global.peerServer,
            port:global.peerPort,
            path:'/',
            config:{
                'iceServers': [
                    {urls: "stun:stun.l.google.com:19302"},
                ]
            }

        };
        this.myChunks = [];
        this.clientChunks = [];

        this.myMediaRecorder = {};
        this.clientMediaRecorder = {};


        this.peer = new Peer(callOptions);
        var self = this;
        this.peer.on('open', function (peerId){
            self.peerId = peerId;
            console.log(peerId);
            self.ws = new WebSocket(global.signalServer+'?access_token='+tokenAPI+'&peer_id='+peerId);
            
            self.ws.onmessage = function (e){
                let data = JSON.parse(e.data);
                switch (data.type){
                    case 'ListCall':
                        console.log('ListCall');
                        break;
                }
            }

            
        });
        this.peer.on('connection',function (conn){
            console.log('connection ');
            console.log(conn);
        });
        this.peer.on('call', function(call) {
            self.peercall = call;
            self.setState({call:true,stateCall:1});
        });
        this.peer.on('destroy',function (){
            console.log('destroy');
            this.peer = new Peer(callOptions);
        });
        this.peer.on('close',function (){
            if(!self.ws){
                alert("Соединенеи с сокет-сервером отсутствует");
                return;
            }
            self.ws.onmessage = function (e){
                let data = JSON.parse(e.data);
                switch (data.type){
                    case 'createRoom':
                        console.log(data);
                        break;
                    case 'closeRoom':
                        self.mediaStream.getTracks().forEach(function(track) {
                            if (track.readyState == 'live' && 
                            (track.kind === 'audio' || track.kind === 'video')) {
                                track.stop();
                            }

                        });

                        self.setState({call:null,stateCall:0});
                        break;
                }
            }/**/
            
        });
        this.peer.on('disconnected',function (){
            console.log('disconnect');
            this.peer.destroy();
        });



    }

    disconnect(){
        this.peercall.close();
        this.myMediaRecorder.stop();
        this.clientMediaRecorder.stop();
        this.ws.send(JSON.stringify({type:'closeRoom',info:{room_id:this.state.roomId}}));
    }

    renderVideoAnswerByState(state) {
        if(state === true){
            return <>
                &nbsp;
                <div onClick={() => this.answer({video:true})} className="btn btn-success btn-sm">
                    <i className="fas fa-video"></i>
                    <span>&nbsp;Ответить с видео</span>
                </div>
            </>
        }else{
            return <>
            </>
        }
    }
        
    blobToFile = (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }

    async playRecords(inner, outer) {
        if(!inner)return "";
        //if(!outer)return "";
        
        document.getElementById("CallListinner").innerHTML = "";
        document.getElementById("CallListouter").innerHTML = "";

        let VideoInner = global.apiLink + 'api/call/call/get-file?link=' + inner;
        let VideoOuter = global.apiLink + 'api/call/call/get-file?link=' + outer;

        let VideoInnerObj = document.createElement("video");
        let VideoOuterObj = document.createElement("video");

        VideoOuterObj.setAttribute("width", "100%");
        VideoInnerObj.setAttribute("width", "100%");

        VideoOuterObj.setAttribute("autoplay", true);
        VideoInnerObj.setAttribute("autoplay", true);
        
        VideoOuterObj.id = "VideoOuterObj";
        VideoInnerObj.id = "VideoInnerObj";
        document.getElementById("CallListinner").appendChild(VideoInnerObj);
        document.getElementById("CallListouter").appendChild(VideoOuterObj);

        document.getElementById("VideoOuterObj").src = VideoOuter;
        document.getElementById("VideoInnerObj").src = VideoInner;
        document.getElementById("VideoInnerObj").addEventListener("timeupdate", ()=>{
            this.progressUpdate();
        });
        document.getElementById('VideopositionBarTransparentCover').addEventListener('click', function (e) {
            let VPBW = document.getElementById('VideopositionBarWrapper');
            let positionBar = document.getElementById("VideopositionBar");
            let video = document.getElementById("VideoInnerObj");
            
            let VPBWRect = VPBW.getBoundingClientRect()
            let x = e.pageX - VPBWRect.x,
            clickedValue = Math.round(x * 100 / VPBWRect.width);
            positionBar.style.width = clickedValue + "%";

            let clickedValueSec = Math.round(clickedValue / 100 * video.duration)
            positionBar.innerHTML = clickedValueSec + " сек ";
            video.currentTime = clickedValueSec;
            document.getElementById("VideoOuterObj").currentTime = clickedValueSec;
            this.setState({VideoPlayerStatus:"play"});
        })
    }

    progressUpdate() {
        let video = document.getElementById("VideoInnerObj");
        // Устанавливаем позицию воспроизведения
        let positionBar = document.getElementById("VideopositionBar");
        let videoWidth = (video.currentTime / video.duration * 100)  + "%";
        positionBar.style.width = videoWidth;
        
        // Заполняем текстовую надпись текущим значением
        positionBar.innerHTML = Math.round(Math.round(video.currentTime*100)/100) + " сек";
      }
      
    renderVideoPlayPause(state) {
        if(state == "play"){
            return <>
                <div id="VideoCallPause" onClick={()=>{
                    this.setState({VideoPlayerStatus:"pause"});
                    document.getElementById("VideoInnerObj").pause();
                    document.getElementById("VideoOuterObj").pause();
                }}>
                    <i className="fas fa-pause"></i>
                </div>
            </>
        }else if(state === "pause"){
            return <>
                <div id="VideoCallPlay" onClick={()=>{
                    this.setState({VideoPlayerStatus:"play"});
                    document.getElementById("VideoInnerObj").play();
                    document.getElementById("VideoOuterObj").play();
                }}>
                    <i className="fas fa-play"></i>
                </div>
            </>
        }
    }
    
    renderCallListByState(state) {

        if(state === false){
            return <>
                <div className="col-md-6">
                    -
                </div>
            </>
        }else{
            let style_VPBTC = {
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: 99,
            };
            return <>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="video_container">
                                <div id="CallListinner"></div>
                                <div id="CallListouter"></div>
                                {this.renderVideoPlayPause(this.state.VideoPlayerStatus)}
                            </div>
                            <div className="video_control">
                                <div id="VideopositionBarWrapper" className="progress" style={{position: "relative"}}>
                                    <div id="VideopositionBar" className="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">0 сек</div>
                                    <div id="VideopositionBarTransparentCover" style={style_VPBTC}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            {this.state.CallList.list && this.state.CallList.list.map((element) => {
                                console.log(element);
                                let inner = element.media[0].id;
                                let outer = element.media[1].id;
                                return <>
                                    <div key={element.id} className="card">
                                        <div className="card-body pt-1 pb-1 pl-1 pr-1">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="btn btn-success btn-sm" onClick={() => { this.playRecords(inner, outer) }}
                                                        data-inner={inner}
                                                        data-outer={outer}
                                                    ><i className="far fa-play-circle"></i></div>
                                                </div> 
                                                <div className="col-md-10">
                                                    <div>{element.creat_at}</div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>
                    </div>
                </div>
            </>
        }
    }
    renderAudioWarningByState(state) {

        if(state === false){
            return <>
                <div className="col-md-6">
                    <div className="alert alert-danger" role="alert">
                    <i className="fas fa-microphone-slash"></i>
                    &nbsp;Не найден микрофон! Приём звонков невозможнт
                    </div>
                </div>
            </>
        }else{
            return <>
                <div className="col-md-6">
                    {this.renderButtonByState(this.state.stateCall)}
                </div>
            </>
        }
    }
    renderUploadStatusesByState(state) {

        if(state === false){
            return <>
            </>
        }else{
            return <>
            <div class="card" style={{width: "500px"}}>
                <div class="card-body">
                    <h5 class="card-title">Загрузка разговора на сервер:</h5>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width: state+"%"}} ariaValuenow={state} ariaValuemin="0" ariaValuemax="100">{state}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                
            </>
        }
    }
    renderButtonByState(state) {

        let PicInPic = {
            width: "200px",
            position: "absolute",
            left: "25px",
            bottom: "30px",
        };

        switch (state) {
            case 0:
                return <>
                <div id="call-stone" className="row">
                    <div className="col-md-12">
                        <div className="alert alert-primary" role="alert">
                            <i className="fa fa-phone" />
                            <span>&nbsp;Ожидаем входящий звонок</span>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="statuses" style={{width: "500px"}}>
                            {this.renderUploadStatusesByState(this.state.VideoUpload)}
                        </div>
                    </div>
                </div>
                </>
                break;
            case 1:
                return <> 
                    <div id="call-stone" className="row">
                        <audio src={global.apiLink + "/assets/rings/black_boomer.mp4"} autoPlay playsInline />
                        <div className="col-md-12">
                            <br className="clearfix" />
                            <hr />
                            <br className="clearfix" />
                            <br className="clearfix" />
                            <div onClick={() => this.disconnect()} className="btn btn-danger btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Сбросить</span>
                            </div>
                            &nbsp;
                            <div onClick={() => this.answer()} className="btn btn-success btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Ответить</span>
                            </div> 
                            {this.renderVideoAnswerByState(this.state.VideoAvalable)}
                        </div>
                    </div>
                </>
            case 2:
                return  <>
                    <div id="call-stone" className="row">
                        <div className="col-md-12">
                            <div class="card clientVideoWrapper" style={{width: "500px"}}>
                                <div class="card-body">
                                    <video id="clientVideo" autoPlay playsInline  style={{width: "100%"}}/>
                                    <video id="myVideo" autoPlay playsInline muted style={PicInPic}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <br className="clearfix" />
                            <div onClick={() => this.disconnect()} className="btn btn-danger btn-sm">
                                <i className="fa fa-phone"/>
                                <span>&nbsp;Завершить</span>
                            </div>
                            <br className="clearfix" />
                            <hr />
                            <br className="clearfix" />
                        </div>
                    </div>
                </>
        }
    }


    answer = async(parametrs = {}) =>{
        let self = this;
        if(parametrs.video !== true)parametrs.video = false;
        navigator.mediaDevices.getUserMedia ({ audio: true, video: parametrs.video }).then(mediaStream => {

            self.mediaStream = mediaStream;

            self.peercall.answer(mediaStream); // отвечаем на звонок и передаем свой медиапоток собеседнику
            self.peercall.on('close', function (){
                console.log('close connection');
            }); 
            
            //можно обработать закрытие-обрыв звонка
            self.ws.onmessage= function (e){
                let data = JSON.parse(e.data);
                console.log("self.ws.onmessage");
                console.log(data);
                switch (data.type){
                    case 'close_room':
                        self.mediaStream.getTracks().forEach(function(track) {
                            if (track.readyState == 'live' && 
                            (track.kind === 'audio' || track.kind === 'video')) {
                                track.stop();
                            }
                        });
                        console.log('room canceled');
                        if(self.call === true)self.disconnect();
                        self.setState({
                            call:false,
                            stateCall:0,
                            roomId:data.id_room
                        });
                        break;
                }
            }
            self.setState({stateCall:2})
            setTimeout(function() {
                document.getElementById('clientVideo').srcObject = self.peercall.remoteStream;
                document.getElementById('myVideo').srcObject = self.mediaStream;

                self.myMediaRecorder = new MediaRecorder(mediaStream);
                self.myMediaRecorder.start();

                self.myMediaRecorder.addEventListener("dataavailable", function(event) {
                    self.myChunks = event.data;
                });


                self.myMediaRecorder.onstop = function (e){
                    self.setState({VideoUpload:0});
                    let timeInMs = Date.now();
                    const myBlob = new Blob([self.myChunks], {
                        type: 'video/mp4'
                    });
                    console.log("myMediaRecorder stoped");
                    self.myChunks = [];

                    let url = global.apiLink + 'api/call/call/load-media-to-room?room_id='+self.state.roomId;
                    const mediafile = new File([myBlob], "videofile_"+timeInMs+".mp4", { type: "video/mp4" })

                    let body = new FormData();
                    body.append('file', mediafile);
                    fetch(url, {
                        'headers': headers, 
                        'method': "POST",
                        'body': body,
                    }).then(function(msg){
                        self.setState({VideoUpload:20});
                        console.log("Запись разговора записана и отправлена на сервер");
                    });
                }

                self.clientMediaRecorder = new MediaRecorder(self.peercall.remoteStream);
                self.clientMediaRecorder.start();
                self.clientMediaRecorder.addEventListener("dataavailable", function(event) {
                    self.clientChunks = event.data;
                });
                self.clientMediaRecorder.onstop = function (e){
                    let timeInMs = Date.now();
                    const clientBlob = new Blob([self.clientChunks], {
                        type: 'video/mp4'
                    });
                    console.log("clientMediaRecorder stoped");
                    self.clientChunks = [];
                    let url = global.apiLink + 'api/call/call/load-media-to-room?room_id='+self.state.roomId;
                    const mediafile = new File([clientBlob], "videofile_"+timeInMs+".mp4", { type: "video/mp4" })
        
                    let body = new FormData();
                    body.append('file', mediafile);
                    fetch(url, {
                        'headers': headers, 
                        'method': "POST",
                        'body': body,
                    }).then(function(){
                        self.setState({VideoUpload:100});
                        console.log("Запись разговора записана и отправлена на сервер");
                        alert("Запись разговора записана и отправлена на сервер");
                        self.setState({VideoUpload:false});
                        self.getRooms();
                    });
                }
            },1500);
        }).catch(function(err) { console.log(err.name + ": " + err.message); });
    }
    
    getRooms = () => {
        let self = this;
        let url = global.apiLink + 'api/call/call/get-rooms';
        fetch(url, {
            'headers': headers, 
        }).then(response => response.json())
        .then(function(result){
            self.setState({CallList:result.payload});
            console.log(result);
        }).catch(e => {
            console.log(e);
        });
    }

    checkAudioVideo = () => {
        let self = this;
        navigator.getMedia = ( navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia
        );
        navigator.getMedia({video: true}, function() {
            self.setState({VideoAvalable:true});
        }, function() {
            self.setState({VideoAvalable:false});
        });
        navigator.getMedia({audio: true}, function() {
            self.setState({AudioAvalable:true});
        }, function() {
            self.setState({AudioAvalable:false});
        });
        return true;
    }

    async componentDidMount() {
        this.checkAudioVideo();
        this.getRooms();
    }

    render() {
        const { AudioAvalable, CallList } = this.state;
        return (
            <div>
                <Toppanel title="Дежурный телефон"/>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            {this.renderAudioWarningByState(AudioAvalable)}
                            {this.renderCallListByState(CallList)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CallResource;