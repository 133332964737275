import React from 'react';
import ReactDOM from 'react-dom';
import Toppanel from '../top_panel';

import {TreeQa} from '../../../App';

import "../../../global.js";

let headers = new Headers();
headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

export class WorkAreaQa extends React.Component {

    clearTinyEditor = (alias) => {
        var form = document.getElementById("mig_tv_"+alias);
        var inputs = form.querySelectorAll("input, textarea, selector");
        inputs.forEach((el)=>{ 
            document.getElementById(el.id).value = "";
            console.log(el.id   );
            try {
                window.tinymce.get(el.id).setContent("");
            } catch (err) {
                console.log(err);
            }
        });
        if(form.getAttribute("data-mig-el-counter") !== undefined
        && form.getAttribute("data-mig-el-counter") !== null){
            form.removeAttribute("data-mig-el-counter");
        }
    }

    formationTablePanel = (migname) =>{
            let mtp = document.getElementsByClassName("mig_table_panel");
            for(var el in mtp) {
                let MigCount = document.getElementsByClassName("mig_table_panel")[el].getAttribute("data-migcount");
                ReactDOM.render(
                    <div>
                        <button className='btn btn-primary btn-sm mr-2' name='migel-edit' onClick={(element)=>{this.formationTableMIGElEdit(element, migname, MigCount);}} data-n=''><i className='far fa-edit'></i></button>
                        <button className='btn btn-danger btn-sm' name='migel-remove' onClick={(element)=>{this.formationTableMIGElRemove(element, migname, MigCount);}} data-n=''><i className='far fa-trash-alt'></i></button>
                    </div>
                    ,
                    document.getElementsByClassName("mig_table_panel")[el]
                );
            };
    }

    formationTable = () =>{
        var mig_tv_items = document.getElementById("codex-tvlist-container").querySelectorAll(".mig_tv_item");
        var ftable = document.createElement("table");
        ftable.classList.add("table");
        ftable.classList.add("mt-2");
        ftable.classList.add("table-striped");
        ftable.classList.add("table-bordered");
        mig_tv_items.forEach( (el)=>{ 
            if(document.getElementById("codex_article_item") !== null)
            document.getElementById("codex_article_item").parentNode.removeChild(document.getElementById("codex_article_item"));
                ftable.id = el.name;
                var been = el.value.trim();
                try {
                    var outputmigel = "";
                    var jimmi = JSON.parse(been);
                    for(var prop in jimmi) {
                            outputmigel += "<tr>";
                            var jimmiprop = jimmi[prop];
                            outputmigel += "<td width='20'>"+(parseInt(prop) + 1)+"</td>";
                                for(var propvar in jimmiprop) {
                                    outputmigel += "<td>"+jimmiprop[propvar]+"</td>";
                                }
                            outputmigel += "<td width='100' data-migcount='"+prop+"' class='mig_table_panel'></td>";
                            outputmigel += "</tr>";
                    }
                    ftable.innerHTML = "<tbody>"+outputmigel+"</tbody>";
                    window.insertAfter(el, ftable);
                    this.formationTablePanel(el.name);
                } catch (err) {
                    
                }
         } );

    }


    onlineTranslit = (value) => {
        value = value.trim().replace(/\s+/g,'').toLowerCase();
        return this.cyrill_to_latin(value);
    }

    
    cyrill_to_latin = (text) => {

        var arrru = ['Я','я','Ю','ю','Ч','ч','Ш','ш','Щ','щ','Ж','ж','А','а','Б','б','В','в','Г','г','Д','д','Е','е','Ё','ё','З','з','И','и','Й','й','К','к','Л','л','М','м','Н','н', 'О','о','П','п','Р','р','С','с','Т','т','У','у','Ф','ф','Х','х','Ц','ц','Ы','ы','Ь','ь','Ъ','ъ','Э','э'];
        var arren = ['Ya','ya','Yu','yu','Ch','ch','Sh','sh','Sh','sh','Zh','zh','A','a','B','b','V','v','G','g','D','d','E','e','E','e','Z','z','I','i','J','j','K','k','L','l','M','m','N','n', 'O','o','P','p','R','r','S','s','T','t','U','u','F','f','H','h','C','c','Y','y','','','','','E', 'e'];
    
        for(var i=0; i<arrru.length; i++){
            var reg = new RegExp(arrru[i], "g");
            text = text.replace(reg, arren[i]);
        }

        return text;
    }

    
    FindCodexResource = async () => {

        var SeRCHtEXT = document.getElementById('find_codex_input').value;
        console.log(SeRCHtEXT);
        if(SeRCHtEXT.length < 3)return false;

        let API_link = global.apiLink+`api/codex/codex/list?search=${SeRCHtEXT}`;
        try {
            const api_response = await 
                fetch( API_link, {
                    method: 'GET',
                } );
            if(api_response.ok){
                let api_response_var = await api_response.json();
                console.log(api_response_var);
                this.FormationCodexList(api_response_var);
            } else {
                alert("Ошибка HTTP: " + api_response.status);
                localStorage.removeItem('tokenAPI');
                return false;
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
        
    }

    FormationCodexList = (api_response_var) => {
        var ContentContainer = document.getElementById('find_codex_list')
        var outputStr = "<table class='table table-striped table-bordered table-hover table-sm mt-3'>"
        let listCodex = api_response_var.payload.list;
        console.log('dsadasdads')
        for(let i = 0; i < listCodex.length; i++){
            outputStr = outputStr+"<tr class=''><td>"+
            "<span class='btn btn-info btn-sm' style='cursor:pointer;' onclick='"+'$(".close").click();document.getElementById("form_codex_id").value='+listCodex[i].id+";'>" + (listCodex[i].id) + "</span> </td>"+
            "<td>" + (listCodex[i].title) + "</td></tr>";
        }
        ContentContainer.innerHTML = outputStr + "</table>";
    }

    resourceSave = async (e) => {
        e.preventDefault();
        var resourceid = document.getElementById('form-resource-create').getAttribute("data-resourceid");
        
        const tokenAPI = localStorage.getItem("tokenAPI");
        const Data = new FormData();
        let API_link = global.apiLink+`api/qa/qa/add?token=${tokenAPI}`;
        if(resourceid !== null){
            API_link = global.apiLink+`api/qa/qa/edit?token=${tokenAPI}`;
            Data.append('resourceid', resourceid);
        } 
        if(
            document.getElementById('form_resource_title').value !== document.getElementById('form_resource_title').getAttribute("data-value")
        ) Data.append('title', document.getElementById('form_resource_title').value);
        
        if(
            document.getElementById('form_resource_tags').value !== document.getElementById('form_resource_tags').getAttribute("data-value")
        ) Data.append('tags', document.getElementById('form_resource_tags').value);

        Data.append('codex_id', document.getElementById('form_codex_id').value);
            let fr_content = "";
        try {
            fr_content = window.tinymce.get('form_resource_content').getContent();
        } catch (err) {
            console.log(err);
        }
        //if(fr_content == "")fr_content = document.getElementById('form_resource_content').value;

        Data.append('content', fr_content);
        
        try {
            const api_response = await 
            fetch(API_link, {
                headers: headers,
                method: 'POST',
                body: Data,
            });
            if(api_response.ok){
                let api_response_var = await api_response.json();
                //this.resourceTvSave(api_response_var.payload.id);
                //document.querySelector("[name=refreshtree]").click();
                if(api_response_var.payload.id > 0) alert("Готово");
                else alert("Ошибка");
                
            } else {
                alert("Ошибка HTTP: " + api_response.status);
                //localStorage.removeItem('tokenAPI');
                return false;
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }
    }
    
    render(){
        //this.resourceGetTemplates();
        console.log('tut');
        const Tc = new TreeQa();
        return (
            <div id="codex-work-area" className="col-md-8 float-right">
                <div id="resource-create">
                    <form id="form-resource-create">
                        <div className="row">
                            <div className="col-md-12 text-right">
                                <button className="btn btn-primary btn-sm" onClick={this.resourceSave}>Сохранить</button>
                            </div>
                            
                            <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="form_codex_id">ID ресурса Кодекс</label>
                                            <div className="input-group">
                                                <div className="custom-file">
                                                <input type="text" name="form_codex_id" id="form_codex_id" className="form-control" placeholder="ID раздела Кодекс" />
                                                </div>
                                                <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" style={{"margin-top":"-1px"}} data-toggle="modal" data-target="#find_codex_modal" type="button">Указать</button>
                                                </div>
                                            </div>
                                        </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput">Вопрос</label>
                                    <input type="text" className="form-control" name="form_resource_title" id="form_resource_title" placeholder="Вопрос" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="formGroupExampleInput">Теги</label>
                                    <input type="text" className="form-control" name="form_resource_tags" id="form_resource_tags" placeholder="тэги" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="validationTextarea">Ответ</label>
                            <textarea className="form-control richtext"  name="form_resource_content" id="form_resource_content"></textarea>
                        </div>
                    </form>
                </div>
                <div id="resource-tv-wrapper">
                    <div className="row">
                        <div id="codex-tvlist-container" className="col-md-12"></div>
                    </div>
                </div>
                
                <div className="modal fade" id="find_codex_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Выбрать ресурс кодекса</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="form-inline">
                                <div className="form-group">
                                    <input type="text" id="find_codex_input" onChange={()=>this.FindCodexResource()}  className="form-control mx-sm-6" placeholder="Найти ресурс кодекса" />
                                </div>
                            </form>
                            <div id="find_codex_list">

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Отмена</button>
                            <button type="button" className="btn btn-primary">Применить</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );


    }
}

class Qa extends React.Component {
    render(){
        return (
            <div>
                <Toppanel title="Управление разделом Вопрос-ответ" />
                
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <TreeQa />
                            <WorkAreaQa />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Qa;