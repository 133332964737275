import React from 'react';
import '../style.css'
class TypeSubjectForm extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            name:'',
            imagePreviewUrl:'',
            file:null,
            id:props.id
        };
    }
    save = async (e) => {
        e.preventDefault();
        const tokenAPI = localStorage.getItem("tokenAPI");
        let formData = new FormData();
        formData.append('name',this.state.name);
        formData.append('icon',this.state.file);
        formData.append('id',this.state.id);
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink + "subjects/type/edit?id="+this.state.id;
        const api_url = await fetch(url, {method: 'POST', body: formData,headers:headers});
        const json = await api_url.json();
        if (json.status !== 200) {
            alert('some thing went wrong(((');
        }
        if(json.payload){
            window.location = '/interface/inf/type-subjects/'+json.payload.id;
        }


    }
    onFileInputChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result
            });
        }

        reader.readAsDataURL(file)
    }
    componentDidMount() {
        let id = this.state.id;
        if(!id){
           return  false;
        }
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink + 'subjects/type/get?id=' + id;
        fetch(url,{headers:headers})
            .then(res=>res.json())
            .then((result)=>{
                if(result.status == 200){

                    let response = result.payload;
                    console.log(response.name);
                    this.setState({
                        name:response.name,
                        imagePreviewUrl:response.img_src
                    })
                }
            })
    }

    render(){
        let {imagePreviewUrl} = this.state;
        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (<img src={imagePreviewUrl}/>);
        } else {
            imagePreview = (<div className="previewText">Выберите изображение для иконки</div>);
        }
        return(
                <form id="form-resource-create" onSubmit={(e)=>this.save(e)} >
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm">Сохранить</button>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Название</label>
                                <input type="text" className="form-control" name="name" id="form_resource_title"
                                       value={this.state.name} placeholder="Название" onChange={e => this.setState({name: e.target.value})} />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Картинка превью</label>
                                <br/>
                                <input className="fileInput" type="file"  onChange={(e) => this.onFileInputChange(e)}/>
                            </div>
                        </div>
                        <div className="col-md-9" id="icon-preview">
                            {imagePreview}
                        </div>
                    </div>
                </form>
        );

    }
}
export default TypeSubjectForm;