import React from 'react';
import Toppanel from '../top_panel';


export class WorkAreaQa extends React.Component {


    resourceGetTemplates = async () => {
        
        const tokenAPI = localStorage.getItem("tokenAPI");
        const api_url = await 
        fetch(`http://78.29.13.96:3002/api/templates/templates/list?token=${tokenAPI}&groupid=2`);
        const json = await api_url.json();
        if(json.status !== 200){
            alert("Не верные данные авторизации");
            localStorage.removeItem('tokenAPI');
            return false;
        }
        var templatesData = await json.payload;
        if(templatesData){
            var frit = document.getElementById("form_resource_id_template");
            frit.innerHTML = "";
            templatesData.array.forEach(element => {
                var newOption = document.createElement("option");
                newOption.innerHTML = element.title;
                newOption.value = element.id;
                frit.appendChild(newOption);
            });
        }   
    }
    
    render (){
        return (
            <div>
                <Toppanel title="Парсинг Docx" />
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                Загрузка...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Qa extends React.Component {

parseDocxFunc = async (e) =>{
    
    const tokenAPI = localStorage.getItem("tokenAPI");
    let uri = `http://78.29.13.96:3002/api/parsingdocx/pdocx/?token=${tokenAPI}`;
    let api_url = await fetch(uri, {
        method: 'GET',
    });
    let json = await api_url.json();
    if(json.status !== 200){
        alert("Не верные данные авторизации");
        localStorage.removeItem('tokenAPI');
    }else{
        document.getElementById("docxloadingsttus").innerHTML = '<div class="progress">'+
        '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>'+
      '</div>Готово';
    }
}


    render (){
        this.parseDocxFunc();
        return (
            <div>
                <Toppanel title="Парсинг Docx" />
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" id="docxloadingsttus">
                            <div className="progress">
                                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width:"50%"}}></div>
                            </div>Загрузка...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Qa;