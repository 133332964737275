import React from 'react';

class ListUsers extends React.Component{

    constructor(props) {
        super(props);
        let idCurrent = null;
        if (props.idCurrent) {
            idCurrent = props.idCurrent;
        }
        this.state = {idCurrent:idCurrent,elements:[],countItems:0}
    }
    componentDidMount() {
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink+'/api/user/list';
        try{
            fetch(url,{
                headers:headers
            })
                .then(res=>res.json())
                .then((response)=>{

                    if(response.status === 200){
                        this.setState({elements:response.payload.userlist});
                    }
                    else if(response.status === 401){
                        localStorage.setItem('tokenAPI','');

                    }

                })
                .catch((err)=>{
                    console.log('something went wrong');
                });
        }
        catch (error){
            console.log(error);
        }

    }
    add(){
        window.location.href = '/interface/users/0';
    }
    delete(id){
        let url  = global.apiLink +'/api/user/remove?id='+id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response=>response.json())
            .then((response)=>{
                if(response.status === 200){
                    let elements = this.state.elements;
                    elements = elements.filter( element => element.id != id);
                    this.setState({elements:elements});
                    if(id == this.state.idCurrent){
                        window.location.href = '/interface/roles/';
                    }
                }
                else{
                    alert('что-то пошло не так');
                }
            })
    }
    render() {
        const {elements} = this.state;
        //console.log(elements);
        const treeElements = elements.map(element=>
            <li>
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <a className="resource_title" data-toggle="tooltip" data-placement="top" href={"/interface/users/"+element.id}
                   title="Уголовный кодекс Российской Федерации">
                    <span>{element.login}</span>
                </a>
                <i className="fas fa-minus-circle ml-2 resource_remove" title="Удалить"
                   aria-hidden="true" onClick={()=>this.delete(element.id)}/>
            </li>
        );
        return (
            <div className="col-md-4">
                <div className="row">
                    <ul className="mb-1 pb-0">
                        <li>
                            {React.createElement('i',{className: 'fas fa-globe'},)}
                            <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title"  data-toggle="tooltip" data-placement="top" title="Роли">Роли</a>
                            <i className="fas fa-plus-circle ml-2 resource_add_child" datapid="0"
                               title="Добавить" aria-hidden="true" onClick={()=>this.add()}/>
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3">
                        <ul>
                            {treeElements}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default ListUsers;