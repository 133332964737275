import React from 'react';

class List extends React.Component{
    constructor(props) {
        super(props);
        let elements = [];
        this.state ={elements:elements};
    }
    componentDidMount() {
        let url = global.apiLink +'/api/permission/list';
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        fetch(url,{
            headers:headers
        })
            .then(response =>response.json())
            .then((response)=>{
                if(response.status === 200){
                    this.setState({elements:response.payload});
                }
                else if(response.status === 401){
                    localStorage.setItem('tokenAPI','');

                }

            });

    }
    delete(id){

    }
    editResourse(id){

    }
    render() {
        const {elements} = this.state;
        const treeElements = elements.map(element=>
            <li>
                <i className="fas fa-book mr-2" aria-hidden="true"/>
                <a className="resource_title" data-toggle="tooltip" data-placement="top" href={"/interface/permissions/"+element.id}>
                    <span>{element.title}</span>
                </a>
                <i className="fas fa-minus-circle ml-2 resource_remove" title="Удалить"
                   aria-hidden="true" onClick={()=>this.delete(element.id)}/>
            </li>
        );
        return(
            <div className="col-md-4">
                <div className="row">
                    <ul className="mb-1 pb-0">
                        <li>
                            {React.createElement('i',{className: 'fas fa-globe'},)}
                            <span className="resource_title " data-toggle="tooltip" data-placement="top" title="Рут"></span> <a className="resource_title"  data-toggle="tooltip" data-placement="top" title="Роли">Роли</a>
                            <i className="fas fa-plus-circle ml-2 resource_add_child" datapid="0"
                               title="Добавить" aria-hidden="true" onClick={()=>this.editResourse(0)}/>
                        </li>
                    </ul>
                    <div id="codex-tree-container" className="col-md-11 ml-3">

                        <ul>

                            {treeElements}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default List;