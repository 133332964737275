import React from 'react';
import Toppanel from "../top_panel";
import TreeInformation from "./treeinformation";
import  { BrowserRouter, Switch, Route }  from 'react-router-dom';
import FormRegions from "./formregions";
import FormSubjects from "./formsubjects";
import TypeSubject from "./type-subject/typesubjectform";

class Information extends React.Component{

    render() {
      return(

          <div>
              <Toppanel title="Управление справочной информацией" />

              <div className="card">
                  <div className="card-body">
                      <div className="row">

                          <TreeInformation/>

                          <div id="information-work-area" className="col-md-8 float-right">
                              <Switch>
                                <Route exact path="/interface/inf/regions/:id" component={FormRegions}/>
                                <Route exact path="/interface/inf/subject/:id" component={FormSubjects}/>
                              </Switch>
                          </div>

                      </div>
                  </div>
              </div>

          </div>
      );
    }
}
export default Information;