import React from 'react';
import {YMaps, Map, SearchControl, Placemark} from 'react-yandex-maps';
import './style.css';
import InputMask from 'react-input-mask';
import $ from 'jquery'
import suggession from 'suggestions-jquery';


class FormSubjects extends React.Component {

    constructor(props) {
        super(props);
        let phones = [{id: 0, data: ''}];
        let id = 0;
        if (props.match.params.id) {
            id = props.match.params.id;
        }


        this.state = {
            id: id,
            coordX: 0,
            coordY: 0,
            name: '',
            defaultCoords: [55.751574, 37.573856],
            phones: phones,
            site: '',
            types: [],
            typeId: 0,
            addressStr: '',
            time:[[0,0],[0,0],[0,0],[0,0],[0,0],[0,0],[0,0]]
        };

    }


    get = async (id) => {
        let url = global.apiLink + '/subjects/subject/get?id=' + id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let api_url = await fetch(url,{
            headers:headers
        });

    }

    deleteContact(i) {
        let phones = this.state.phones;
        delete phones[i];
        phones = phones.slice(0, i).concat(phones.slice(i + 1))
        this.setState({phones: phones});
    }

    componentDidMount() {
        let id = this.state.id;
        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));
        let url = global.apiLink + '/subjects/subject/get?id=' + id;

        fetch(url,{headers:headers}).then(res => res.json()).then(
            (result) => {
                let typeId = -1;
                if (result.status == 200) {

                    if (result.payload.type) {
                        typeId = result.payload.type.id;
                    }


                }
                let types = [{"id": null, "name": "Не выбранно"}];
                if (Array.isArray(result.payload.types)) {
                    result.payload.types.forEach((element) => {
                        if (element.hasOwnProperty('id') && element.hasOwnProperty('name')) {
                            types.push(element);
                        }

                    });
                }
                this.setState({
                    id: result.payload.id,
                    coordX: result.payload.coordx,
                    coordY: result.payload.coordy,
                    name: result.payload.name,
                    phones: Array.isArray(result.payload.contacts) ? result.payload.contacts : [{id: 0, data: ''}],
                    site: result.payload.site,
                    addressStr:result.payload.str_address,
                    typeId: typeId,
                    types:types,
                    time:result.payload.schedule ? result.payload.schedule: this.state.time
                });
            }
        );
        let self = this;
        let suggestionGLOBAL = {};
        $('#form_resource_address').suggestions({
            token: 'ad483a487debfff5856f869f7d375a34d796542f',
            type: 'ADDRESS',
            count: 15,
            onSelect: function (suggestion) {
                let coordX = suggestion.data.geo_lat;
                let coordY = suggestion.data.geo_lon;
                self.setState({
                    coordX: coordX,
                    coordY: coordY
                });
                suggestionGLOBAL = suggestion;
            }
        });
    }

    addPhoneInput() {
        this.setState(prevState => ({phones: [...prevState.phones, {id: 0, name: ''}]}));
    }

    handleChangePhones(i, event) {
        let phones = [...this.state.phones];
        phones[i].data = event.target.value;
        this.setState({phones: phones});
    }

    resultSelect(e) {
        let coords = e.originalEvent.target.state._data.results[0].geometry._coordinates;
        this.setState({coordX: coords[0], coordY: coords[1]})
    }

    getCoordinate() {
        if (this.state.coordX && this.state.coordY) {
            return [this.state.coordX, this.state.coordY];
        }
        return this.state.defaultCoords;
    }

    save = async (e) => {
        e.preventDefault();
        const tokenAPI = localStorage.getItem("tokenAPI");
        let formData = new FormData(e.target);
        formData.append('token', tokenAPI);
        const urlParams = new URLSearchParams(window.location.search);
        formData.append('region_id', urlParams.get('region_id'));
        formData.delete('phone');
        formData.append('phones', JSON.stringify(this.state.phones));
        formData.append('site', this.state.site);
        formData.append('type_id', this.state.typeId);
        formData.append('str_address',this.state.addressStr);

        formData.append('schedule',JSON.stringify(this.state.time));

        let headers = new Headers();
        headers.append('Authorization','Bearer '+localStorage.getItem('tokenAPI'));

        let url = global.apiLink + "/subjects/subject/edit";
        const api_url = await fetch(url, {method: 'POST', body: formData,headers:headers});
        const json = await api_url.json();

        if (json.status === 200) {
            this.setState({id: json.payload});
            window.location = "/interface/inf/subject/" + json.payload + "?region_id=" + urlParams.get('region_id');
        }
    }
    setDefaultSchedule(){
        console.log(this.state.time);
        const defaultTime = [["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],["09:00","18:00"],[0 ,0],[0,0]];
        this.setState({time:defaultTime});
        console.log(this.state.time);
    }
    handleChangeTime(e,index,part){
        let value = e.target.value;
        let time  = this.state.time;
        time[index][part] = value;
        this.setState({time:time});
    }
    render() {
        const phones = this.state.phones;

        return (
            <div>
                <form id="form-resource-create" onSubmit={(e) => this.save(e)}>
                    <div className="row">
                        <div className="col-md-12 text-right">
                            <button type="submit" className="btn btn-primary btn-sm">Сохранить</button>
                        </div>

                        <input type="hidden" id="id-form-edit" name="id" value={this.state.id}/>
                        <input type="hidden" name="coordX" value={this.state.coordX}/>
                        <input type="hidden" name="coordY" value={this.state.coordY}/>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Название</label>
                                <input type="text" className="form-control" name="name" id="form_resource_title"
                                       value={this.state.name} onChange={e => this.setState({name: e.target.value})}
                                       placeholder="Название"/>


                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="row">
                                <div className="form-group col-md-5">
                                    <fieldset>
                                        {phones.map((element, i) =>

                                            <div className="phone-div" key={element.id}>

                                                <InputMask mask="+7 (999) 999-99-99" data-id={element.id} type="tel"
                                                           maskChar={null} name="phone[]" value={element.data || ''}
                                                           onChange={this.handleChangePhones.bind(this, i)}
                                                           className="form-control"/>

                                                <i className="delete-icon"
                                                   onClick={() => this.deleteContact(i)}>x</i>
                                            </div>
                                        )}
                                    </fieldset>
                                </div>
                                <div>
                                    <button type="button" onClick={() => this.addPhoneInput()} className="btn btn-info">
                                        добавить еще номер
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label>Тип организации</label>
                                <select className="form-control" value={this.state.typeId}
                                        onChange={e => this.setState({typeId: e.target.value})}>
                                    {this.state.types.map((element) => <option key={element.id}
                                                                               value={element.id}>{element.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Дни работы</label>
                                <div className="col-md-12">
                                    <div className="week-days-work">
                                        <div className="day-week">
                                            <label>Понедельник</label>
                                            <br/>
                                            <input type="time" value={this.state.time[0][0]} onChange={(e) => this.handleChangeTime(e,0,0)}/>
                                            <input type="time" value={this.state.time[0][1]} onChange={(e) => this.handleChangeTime(e,0,1)}/>
                                        </div>
                                        <div className="day-week">
                                            <label>Вторник</label>
                                            <br/>
                                            <input type="time" value={this.state.time[1][0]} onChange={(e) => this.handleChangeTime(e,1,0)}/>
                                            <input type="time" value={this.state.time[1][1]} onChange={(e) => this.handleChangeTime(e,1,1)} />
                                        </div>
                                        <div className="day-week">
                                            <label>Среда</label>
                                            <br/>
                                            <input type="time" value={this.state.time[2][0]} onChange={(e) => this.handleChangeTime(e,2,0)}/>
                                            <input type="time" value={this.state.time[2][1]} onChange={(e) => this.handleChangeTime(e,2,1)}/>
                                        </div>
                                        <div className="day-week">
                                            <label>Четверг</label>
                                            <br/>
                                            <input type="time" value={this.state.time[3][0]} onChange={(e) => this.handleChangeTime(e,3,0)}/>
                                            <input type="time" value={this.state.time[3][1]} onChange={(e) => this.handleChangeTime(e,3,1)}/>
                                        </div>
                                        <div className="day-week">
                                            <label>Пятница</label>
                                            <br/>
                                            <input type="time" value={this.state.time[4][0]} onChange={(e) => this.handleChangeTime(e,4,0)}/>
                                            <input type="time" value={this.state.time[4][1]} onChange={(e) => this.handleChangeTime(e,4,1)}/>
                                        </div>
                                        <div className="day-week">
                                            <label>Суббота</label>
                                            <br/>
                                            <input type="time" value={this.state.time[5][0]} onChange={(e) => this.handleChangeTime(e,5,0)}/>
                                            <input type="time" value={this.state.time[5][1]} onChange={(e) => this.handleChangeTime(e,5,1)}/>
                                        </div>
                                        <div className="day-week">
                                            <label>Воскресенье</label>
                                            <br/>
                                            <input type="time" value={this.state.time[6][0]} onChange={(e) => this.handleChangeTime(e,6,0)}/>
                                            <input type="time" value={this.state.time[6][1]} onChange={(e) => this.handleChangeTime(e,6,1)}/>
                                        </div>

                                    </div>
                                    <button type="button" onClick={()=>this.setDefaultSchedule()}>Дефолтное время</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Сайт</label>
                                <input type="text" className="form-control" name="site" id="form_resource_site"
                                       value={this.state.site} placeholder="Сайт"
                                       onChange={e => this.setState({site: e.target.value})}/>
                                <div><a href={'//' + this.state.site} target="_blank">Перейти на сайт</a></div>


                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Адрес</label>
                                <input type="text" className="form-control" name="address" id="form_resource_address"
                                       placeholder="Введите адресс" value={this.state.addressStr} onChange={e =>this.setState({addressStr:e.target.value})} />


                            </div>
                        </div>


                        <div className="col-md-8" id="maps-container">
                            {(this.state.coordX && this.state.coordY) &&
                            <YMaps query={{
                                apikey: 'df639532-a255-4e82-b3e0-06ad7483393e',
                            }}>
                                <Map
                                    defaultState={{
                                        center: this.getCoordinate(),
                                        zoom: 17,
                                        controls: [],
                                    }}
                                    width={'500px'}
                                    height={'400px'}
                                >

                                    <Placemark geometry={this.getCoordinate()}/>

                                </Map>
                            </YMaps>
                            }

                        </div>

                    </div>
                </form>

            </div>
        );
    }
}

export default FormSubjects;