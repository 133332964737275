import React from 'react';
import {Link} from "react-router-dom";

class ElementSidebar extends React.Component{

    constructor(props) {
        super(props);
    }
    render() {
        return(
            <li>
                <a className="sidebar-menu-button" href={this.props.url} target={this.props.target}>
                    <i className={this.props.iconClass}></i>
                    {this.props.name}
                </a>
                {this.props.subMenu &&
                    <ul className="sub-list">
                        {this.props.subMenu.map((element,index)=>
                            <li key={"sub-list"+this.props.name+" "+index}>
                                <Link to={element.url} target={element.target}>
                                    <i className={element.iconClass}></i>
                                    {element.name}
                                </Link>
                            </li>
                            )}
                    </ul>

                }
            </li>
        );
    }
}
export default ElementSidebar;